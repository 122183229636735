import { Button } from "@sberdevices/plasma-ui";
import { useEffect } from "react";


export default function StartSlide({video, onNext=()=>{}}) {

    useEffect(()=> {
        // document.getElementsByClassName('elena_video')[0].play()
    }, [])

    return (
        <div>
            <style>{`
                body {  background: black }
            `}</style>

        
        <div style={{textAlign:'center', position:'relative', zIndex: 1, paddingTop: '25vh'}} onClick={()=>{document.getElementsByClassName('elena_video')[0].play()}}>
            {/* <img src={'/yofs_logo.png'} style={{ position:'absolute', top: '2vh', right:'22vh', width:'17vh' }} /> */}
            {/* <img src={'/kc_logo.png'} style={{ position:'absolute', top: '2vh', right:'22vh', width:'13vh' }} /> */}
            <img src={'/kc_logo_h.png'} style={{ position:'absolute', top: '0.5vh', right:'2vh', width:'50vh' }} />
            <img src={'/yofs_logo.png'} style={{ position:'absolute', top: '0.5vh', right:'55vh', width:'20vh' }} />

            {/* <img src={'/front_robot.png'} width={300} height={370} style={{ marginTop:'5vh', marginBottom: -100}} /> */}
            {/* <p style={{marginTop:'3vh', textShadow:'rgb(0 0 0) 0px 0px 9px, rgb(0 0 0) 0px 0px 23px'}}>Интерактивный тест</p> */}
            {/* <p style={{marginTop:'3vh', textShadow:'rgb(0 0 0) 0px 0px 9px, rgb(0 0 0) 0px 0px 23px'}}>Интерактивный тест</p> */}
            {/* <h1 style={{fontSize:60, textShadow:'rgb(0 0 0) 0px 0px 9px, rgb(0 0 0) 0px 0px 23px'}}>Кто ты в науке?</h1> */}
            <h1 style={{fontSize:60, textShadow:'rgb(0 0 0) 0px 0px 9px, rgb(0 0 0) 0px 0px 23px'}}>Кто ты в науке?</h1>
            <h2 style={{fontWeight:"normal", lineHeight:1.2}}>Хотите узнать, к каким наукам у Вас есть склонности? <br/>Искусственный интеллект Вам поможет!</h2>
            {/* <p>Отображается название стенда и «кнопка», «нажав» на которую пользователь может начать проходить тест.
Нажатие «кнопки «Начать» ведёт к переходу к этапу «Описание теста»</p> */}
            <br/>
            <Button view="primary" pin={"circle-circle"} style={{    background: 'linear-gradient(270deg,#24e029,#00b9ac)'}} onClick={onNext}>Начнём!</Button>
            {/* <video autoPlay >
                <source src="/who/video/01.mov" type='video/ogg; codecs="theora, vorbis"' />
            </video> */}
        </div>
            <video className="elena_video" style={{position:'absolute', width:'100vw', height:'100vh', top: 0, left: 0, bottom:0, right:0, objectFit: 'cover'}} preload="auto" autoPlay loop playsInline> 
                    {/* <source src="/who/video/01.mov" type="video/mov" /> */}
                    {/* <source src="/who/video/0001.m4v" type="video/mp4" /> */}
                    <source src={video} type="video/mp4" />
            </video>
        </div>
    )
}