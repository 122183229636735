

import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    // // Link,
    // useParams,
    // Redirect,
    useHistory
  } from "react-router-dom";

import StModel from './st_model' 

import axios from 'axios'

import { 
	// LoadingOutlined, DownloadOutlined, CameraOutlined, 
    // QrcodeOutlined 
    CameraFilled, 
} from '@ant-design/icons';

import  {
	Button,
} from '@sberdevices/plasma-ui';


// import { utils.img_blob_to_url, download } from './utils'
import utils from './utils'



function LogoHome() {
	let history = useHistory();
	return (
		<img src="sbersight_demo_logo.svg" 
				onClick={() => history.push('/') }
				style={{verticalAlign: 'middle', width: '90px', margin: '15px'}}/> 
	)
}


export default function Page() {

    let category_style_list = [
        { title: 'Пользовательские', items: [], hide:true, custom_user_loaded: true },

        { title: 'Стрит-арт', items: [] },
        // { title: 'Классика', items: [] },
        // { title: 'Мозаика', items: [] },
    ]

    for ( let i = 1; i <= 32; i++) {
        let cat_index = Math.floor( Math.random() * (category_style_list.length - 1) ) + 1
    //   category_style_list.push({
        category_style_list[ cat_index ].items.push({
            id: 'style'+i,
            filename: 'styles/mini/' + 'style'+i+'.jpg',
            userstyle: false,
            // category: get_random_category_id(categories)
            category_id: cat_index,
        })
    }

    
    return (
        <div>
            <div style={{textAlign:'center'}}>
				<LogoHome/> 
			</div>   

            <StModel
                startText={mi => <>
                    <img src="/st_logo.png" height="200" onClick={() => mi.on_load_src_img_demo('/lisa.jpg')} />
					
					<h1>StyleTransfer</h1>
					<p>Искусственный интеллект нарисует
            			<br /> ваше фото в одном из стилей.</p>
						<br />
					<Button onClick={() => mi.open_dialog('#srcimg')}>
						<CameraFilled /> &nbsp; Загрузить фото
					</Button>
                </>}
                defStyles={category_style_list}
                userStyles={false}
                compareMode={true}
                filenameForDownload="style_transfer.jpg"
                onMagic={(style_info, src_img_blob) => {
                    console.log('onMagic')
                    
                    let qData = new FormData();
                    qData.append("original_img", src_img_blob);

                    if (style_info.userstyle) {
                        qData.append("style_img", style_info.blob);

                        return axios.post('http://45.89.225.221:9101/transfer_with_parameters_and_qr', qData, {
                            // responseType: "arraybuffer",
                            params: {
                                model_type: 'fast',
                                // model_type: 'slow',
                                user_style: true,
                                // style_id: style_info.id
                            },
                        }).then(res => {
                            console.log('res', res)
                            return {
                                painted_img_url:            'http://45.89.225.221:9101/get_file?ref=' + res.data.img,
                                painted_img_url_with_logo:  'http://45.89.225.221:9101/get_file?ref=' + res.data.img_with_logo,
                            }
                        }, err => {
                            console.log('err', err)
                            return false
                        })                        

                        // return axios.post('http://45.89.225.221:9101', qData, {
                        //     responseType: "arraybuffer",
                        //     params: {},
                        // }).then(res => {
                        //     console.log('res', res)
                        //     return {
                        //         painted_img_url:            utils.img_blob_to_url( res.data ),
                        //         painted_img_url_with_logo:  utils.img_blob_to_url( res.data ),
                        //     }
                        // }, err => {
                        //     console.log('err', err)
                        //     return false
                        // })
                    } else {
                        return axios.post('http://45.89.225.221:9101/transfer_with_parameters_and_qr', qData, {
                            // responseType: "arraybuffer",
                            params: {
                                // model_type: 'fast',
                                model_type: 'slow',
                                user_style: false,
                                style_id: style_info.id
                            },
                        }).then(res => {
                            console.log('res', res)
                            return {
                                painted_img_url:            'http://45.89.225.221:9101/get_file?ref=' + res.data.img,
                                painted_img_url_with_logo:  'http://45.89.225.221:9101/get_file?ref=' + res.data.img_with_logo,
                            }
                        }, err => {
                            console.log('err', err)
                            return false
                        })
                    }

                }}
            ></StModel>

            <style>
                { `
                html, body { min-height: 100vh; } 
                
                .footer {
                    text-align: center;
                    position: fixed;
                    z-index: 10;
                    bottom: 0;
                    left: 0;
                    right: 0;
                  }
                  
                ` }
            </style>


            <div className="footer">
                <small>powered by</small>
                <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
            </div>

        </div>
    )
}