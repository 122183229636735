
import { Button } from "@sberdevices/plasma-ui";
import { useState, useEffect } from "react";
import Debug from "../../../elem/Debug";


export default function SelectSlide({question={}, onNext=()=>{}}) {
    const [select_index, set_select_index] = useState(-1)
    const [firstIndex, set_firstIndex] = useState(0)

    const onSelect = (variant, index) => {
        // onNext(variant)
        set_select_index(index)
        setTimeout(() => {
            onNext(variant)
        }, 300)
    }

    useEffect(()=>{
        set_select_index(-1)
        set_firstIndex( Math.round(Math.random()) )
    },[question])

    const variantOne = question?.variants?.[firstIndex]
    const variantTwo = question?.variants?.[1-firstIndex]

    let styleCommon = {
        borderRadius:20,
        width:'min(70vh, 40vw)',
        height:'min(70vh, 40vw)',
        margin:20,
        transition: '300ms'
    }
    let styleStart = {
        transform: 'scale(0.5)',
        opacity: 0
    }
    let styleSelect = {
        transform: 'scale(1.05)',
        opacity: 0
    }
    let styleUnSelect = {
        transform: 'scale(0.95)',
        opacity: 0
    }

    return (
        <div>
            {/* <h1>SelectSlide</h1> */}
            <h1>
                {/* Выберите одно из изображений, которое вам ближе */}
                Что из этого Вам ближе?
            </h1>
            <p>
                {/* <img width="100" src="/who/part1/01-01.jpg" /> */}
                <img src={variantOne?.file} 
                    onMouseDown={()=>onSelect(variantOne, 0)}
                    className="open"
                    style={{
                        ...styleCommon,
                        ...(select_index == 0 && styleSelect ),
                        ...(select_index == 1 && styleUnSelect )
                    }} />
                {/* &nbsp; */}
                {/* <img width="100" src="/who/part1/01-02.jpg" /> */}
                <img src={variantTwo?.file} 
                    onMouseDown={()=>onSelect(variantTwo, 1)}
                    className="open"
                    style={{
                        ...styleCommon,
                        ...(select_index == 1 && styleSelect ),
                        ...(select_index == 0 && styleUnSelect )
                    }} />
                {/* <img width="100" src="/cover_st.jpg" /> */}
                {/* {select_index} */}
            </p>
            {/* <Button onClick={onNext}>Начать</Button> */}
            {/* <Debug json={question} /> */}

            

            <style>

                {`

.zoom {
    animation-name: popup;
    animation-duration: 300ms;
  }
.open {
    animation-name: open;
    animation-duration: 800ms;
  }

 @keyframes popup {
    0%   { transform: scale(1,1) }
    10%  { transform: scale(1.05,1.05) }
    30%  { transform: scale(.95,.95) }
    50%  { transform: scale(1,1) }
    57%  { transform: scale(1,1) }
    64%  { transform: scale(1,1) }
    100% { transform: scale(1,1) }
  }

  @keyframes open {
    0%   { transform: scale(.95,.95); opacity: 0; }
    100%   { transform: scale(1,1); opacity: 1; }
  }
                `}
            </style>

        </div>
    )
}

