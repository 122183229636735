import { Button, TextField } from "@sberdevices/plasma-ui"
import { useEffect, useState } from "react";
import StyleCards from '../../st_cards'; 
import utils from "../../utils";


const img_input_to_blob_base64 = (img_blob) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsBinaryString(img_blob);
        reader.onload = function() {
            let img_base64 = btoa(reader.result);

            resolve(img_base64)
        };
    })
}

function ModelFiledImage({field, value='', onInput=()=>{}}) {
    const [src_img_url, set_src_img_url] = useState( null )
    const [src_img_blob, set_src_img_blob] = useState( null )
    const [src_img_base64, set_src_img_base64] = useState( null )
    const [src_img_width, set_src_img_width] = useState( 0 )
    const [src_img_height, set_src_img_height] = useState( 0 )


	const on_load_src_img = (input) => {
		if (!input.files || input.files.length == 0) return
        set_src_img_url( utils.img_blob_to_url( input.files[0] ) );
        set_src_img_blob( input.files[0] )
        // console.log ( input.files[0], input.files[0].width, input.files[0].clientWidth );
        img_input_to_blob_base64(input.files[0]).then( img_blob_base64 => {
            set_src_img_base64( img_blob_base64 )

            onInput({
                w: src_img_width,
                h: src_img_height,
                blob: src_img_blob,
                url: src_img_url,
                value: img_blob_base64,
            })
        })
	}

	const open_dialog = (id_input) => {
		document.querySelector(id_input).click()
	}

    const get_width_height_img = (img) => {
        // console.log(img.naturalWidth, img.naturalHeight)
        set_src_img_width( img.naturalWidth )
        set_src_img_height( img.naturalHeight )

        onInput({
            w: img.naturalWidth,
            h: img.naturalHeight,
            blob: src_img_blob,
            url: src_img_url,
            value: src_img_base64,
        })
    }

    return (
        <div>
            {/* image */}
            <input id={"srcimg_" + field.proxy_as} style={{display:'none'}} type="file" accept=".jpg,.jpeg,.png" 
				onChange={(e) => on_load_src_img(e.target)}/>

            <Button stretch={true} onClick={() => open_dialog('#srcimg_' + field.proxy_as)} >Load image</Button>
            <br/>
            <img src={src_img_url} width="100" id={"srcimg_" + field.proxy_as + "_preview"}
                onLoad={(e) => get_width_height_img(e.target) }
                onClick={() => open_dialog('#srcimg_' + field.proxy_as)}
                style={{display: src_img_url ? 'block' : 'none', borderRadius:20, marginTop: 10}}
                />
        </div>
    )
}


export default function ModelInputField({field, value='Проверочный текст', onInput=()=>{}}) {
    useEffect(()=>{
        if ( field?.field_type == 'text' ) {
            onInput({value:value}) 
        }
        if ( field?.field_type == 'image' ) {
            onInput({value:null}) 
        }
        if ( field?.field_type == 'image-list' ) {
            onInput({value:null}) 
        }
    },[])

    return (
        <div>
            <p>{field?.description} <span style={{opacity:0.3}}> — {field?.proxy_as}</span></p>
            {/* {field?.field_type} */}
            {/* ModelInputField */}
            {/* <pre style={{fontSize:10, lineHeight:1}}>{JSON.stringify(field, true, "      ")}</pre> */}
            { field?.field_type == 'text' && 
                <TextField value={value} label={field.description} required={field.is_required} onInput={e => onInput({value:e.target.value})} />
            }
            { field?.field_type == 'image' && 
                // 'image'
                // <ModelFiledImage field={field} value={value} onInput={onInput} />
                <ModelStyleCards field={field} value={value} onInput={onInput} />
            }
            { field?.field_type == 'image-list' && 
                // 'image'
                <ModelStyleCards camera={false} field={field} value={value} onInput={onInput} />
            }
        </div>
    )
}


function loadImgSize(url) {
    return new Promise((resolve, reject) => {
        var i = new Image();
        // i.src = 'https://www.master-web.info/wp-content/uploads/2021/09/carousel-wcag.jpeg.pagespeed.ce.ZOKU6K_HRP.jpg';
        i.src = url;
        i.onload = (e) => {
            console.log(url, e.target.naturalWidth, e.target.naturalHeight)
            // resolve(e.target.naturalWidth, e.target.naturalHeight)
            resolve({ w: e.target.naturalWidth, h: e.target.naturalHeight })
        }
    })
}

function ModelStyleCards({field, camera=true, value='', onInput=()=>{}}) {
    const f_to_base64 = (url) => {
        // return url
        return fetch(url)
            .then( t => t.blob().then( b => {
                // set_unethics_cover_img_url( utils.img_blob_to_url(b) ) 
                return img_input_to_blob_base64(b) 
                // return utils.img_blob_to_url(b)
                // return 'asdd'
                // set_my_preloading()
            })) 
    }

    const open_dialog = (id_input) => {
		document.querySelector(id_input).click()
	}

    const [custom_user_styles, set_custom_user_styles] = useState([]);
    const [active_style_id, set_active_style_id] = useState(-1);


    const [src_img_url, set_src_img_url] = useState( null )
    const [src_img_blob, set_src_img_blob] = useState( null )
    const [src_img_base64, set_src_img_base64] = useState( null )
    const [src_img_width, set_src_img_width] = useState( 0 )
    const [src_img_height, set_src_img_height] = useState( 0 )
    

    const on_load_userstyle_img = (input) => {
		if (!input.files || input.files.length == 0) return

        let img_url = utils.img_blob_to_url( input.files[0] )

        loadImgSize(img_url).then((natural_size)=> {

            // console.log('++', ww,hh)

            img_input_to_blob_base64( input?.files?.[0] ).then(b64 => {
                // set_src_img_base64(b64)

                set_custom_user_styles( [
                    {
                        id: 'userstyle' + custom_user_styles.length, 
                        filename: img_url,  
                        blob: input.files[0], 
                        b64: b64,
                        ...natural_size, 
                        userstyle: true,
                    }, 
                    ...custom_user_styles 
                ] )

                
                set_active_style_id('userstyle' + custom_user_styles.length)

                onInput({
                    // value: b64,
                    value: b64,
                    w: natural_size.w,
                    h: natural_size.h,
                })
            })

            // set_custom_user_styles([
            //     ...custom_user_styles
            // ])

            // set_src_img_width(w)
            // set_src_img_height(h)

            // onInput({
            //     // value: src_img_base64,
            //     w: w,
            //     h: h,
            // })
        })


        
        
        // input?.files?.[0].blob().then( b => {
        //     img_input_to_blob_base64(b).then(
        //         b64 => onInput({value: b64 })
        //     )
        //     // set_unethics_cover_img_url( utils.img_blob_to_url(b) ) 
            
        //     // return utils.img_blob_to_url(b)
        //     // return 'asdd'
        //     // set_my_preloading()
        // })
        // f_to_base64(e.filename)?.then( b64 => onInput({value: b64 })) 
	}

    return (
        <>
                
                <StyleCards 
                    // customlist={custom_user_styles}
                    // list={ [ {title: 'Стрит-арт', items: [] } ] }
                    customlist={ [ ...custom_user_styles, ...field.datasource.map((x,i)=> ({filename: x, id:i}) ) ] }
                    // customlist={[
                    //     {filename:'https://sberai.site:8110/fusionbrain/prim_styletransfer.jpg', id:1, hide:false},
                    //     {filename:'https://sberai.site:8110/fusionbrain/prim_styletransfer.jpg', id:2, hide:false},
                    //     {filename:'https://sberai.site:8110/fusionbrain/prim_styletransfer.jpg', id:3, hide:false},
                    //     {filename:'https://sberai.site:8110/fusionbrain/prim_styletransfer.jpg', id:4, hide:false},
                    //     {filename:'https://sberai.site:8110/fusionbrain/prim_styletransfer.jpg', id:5, hide:false},
                    // ]} 
                    list={[]} 
                    active={active_style_id}
                    camera={camera}
                    // onSelect={e => onInput(e)}
                    // onSelect={e => onInput({value:e.filename})}
                    onCameraClick={e => open_dialog('#styleimg_' + field.proxy_as)}
                    onSelect={e  => {
                            set_active_style_id(e.id)
                            // console.log('--', e)
                            // f_to_base64(e.filename)?.then( b64 => onInput({value: b64, url:e.filename })) 

                            onInput({
                                // value: b64,
                                value: camera ? e.b64 : e.filename,
                                w: e.w,
                                h: e.h,
                            })
                        }}
                />
                <input id={"styleimg_" + field.proxy_as} style={{display:'none'}} type="file" accept=".jpg,.jpeg,.png" 
					onChange={(e) => on_load_userstyle_img(e.target)}/>


        </>
    )
}