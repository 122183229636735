
import { Button } from "@sberdevices/plasma-ui";
import Debug from "../../../elem/Debug";


export default function LikeSlide({question={}, onNext=()=>{}}) {
    return (
        // <div onClick={onNext}>
        <div>
            {/* <h1>LikeSlide</h1> */}
            <p>
                {/* <img width="100" src="/cover_st.jpg" /> */}
                <img width="100" src={'/who' + question?.variants?.[0]?.file} />
            </p>
            <Button onClick={()=>onNext(question?.variants?.[0])}>Like</Button>
            <Button onClick={()=>onNext({ ...question?.variants?.[0], value: 0 })}>Dislike</Button>
            {/* <pre style={{lineHeight:1, fontSize:10}}>
                {JSON.stringify(questions[question_index], true, "    ")}
            </pre> */}
            <Debug json={question} />
        </div>
    )
}

