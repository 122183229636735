// import { useEffect, useState } from "react"
import HelpLikeSlide from './slides/HelpLikeSlide'

import { Button } from '@sberdevices/plasma-ui'
import React, { useState, useMemo, useRef, useEffect } from 'react'
// import TinderCard from '../react-tinder-card/index'
import TinderCard from 'react-tinder-card'
// import TinderCard from './re-react-tinder-card'
import './TinderCards.css'
import Debug from '../../elem/Debug'
import { size } from 'lodash'


window.add_answers = null;

function QuestionsPartLikes({video, part={}, onEnd=()=>{} }) {
    const questions = part?.questions
    const [question_index, set_question_index] = useState(0)
    const [answers, set_answers] = useState([])
    const [show_help, set_show_help] = useState(true)

    

    const add_answers = (variant, value, index) => {

        let new_answers = [ ...answers, {...variant, value: value}]
        set_answers( new_answers )
        // console.log('add_answers', variant, value, new_answers ); 

        if ( index == 0 ) {
            // the end
            setTimeout(()=>{
                console.log('onEnd', new_answers ); 
                onEnd( new_answers )
            }, 1000)
        } 
    }

    window.add_answers = add_answers

    const count_variants = questions?.[question_index]?.variants.length 

    // let db = questions.map(x => ({
    //     name: x?.variants?.[0].file,
    //     url: '/who' + x?.variants?.[0].file,
    //     value: x?.variants?.[0].value
    // }))
    let db = questions.map(x => x?.variants?.[0])
    
    const [currentIndex, setCurrentIndex] = useState(db.length - 1)
    const [lastDirection, setLastDirection] = useState()

    // used for outOfFrame closure
    const currentIndexRef = useRef(currentIndex)

    const childRefs = useMemo(
    () =>
        Array(db.length)
            .fill(0)
            .map((i) => React.createRef()),
        []
    )
    
      // то что показываю сейчас, текст вопроса без ответа
      const updateCurrentIndex = (val) => {
        setCurrentIndex(val)
        currentIndexRef.current = val
      }
    
    const canGoBack = currentIndex < db.length - 1
    
    const canSwipe = currentIndex >= 0
    
    // set last direction and decrease current index
    const swiped = (direction, itemToDelete, index) => {
        setLastDirection(direction)
        if ( direction == 'left') {
            window.add_answers( db[index], 0, index)
        } else if (direction == 'right') {
            window.add_answers( db[index], 1, index)
        }
        
        updateCurrentIndex(index - 1)

        console.log('EndSwiped', db[index], direction)
    }
      
    const outOfFrame = (item, idx) => {
        // console.log(`${name} (${idx}) left the screen !`, window.fix_actual_lastDirection, currentIndexRef.current)
        // handle the case in which go back is pressed before card goes outOfFrame
        currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
        // TODO: when quickly swipe and restore multiple times the same card,
        // it happens multiple outOfFrame events are queued and the card disappear
        // during latest swipes. Only the last outOfFrame event should be considered valid
    }
    
    const swipe = async (dir) => {
        // console.log('asinc swipe', dir)
        if (canSwipe && currentIndex < db.length) {
            try {
                await childRefs?.[currentIndex]?.current?.swipe(dir) // Swipe the card!
            } catch (e) {
                console.log(e)
                // console.log(currentIndex, childRefs?.[currentIndex], childRefs?.[currentIndex]?.current, childRefs?.[currentIndex]?.current?.swipe(dir))
            }
        }
    }
    
    // increase current index and show card
    const goBack = async () => {
        if (!canGoBack) return
        const newIndex = currentIndex + 1
        updateCurrentIndex(newIndex)
        await childRefs[newIndex].current.restoreCard()
    }



    useEffect(() => {
        set_answers([])
    },[part])

    if (count_variants == 1 && show_help)  {
        return (
            <div>
                {/* help 2 */}
                <HelpLikeSlide video={video} onNext={() => set_show_help(false)} />


                {/* <Button onClick={() => set_show_help(false)}>next</Button> */}
    
                {/* <Debug json={answers} /> */}
            </div>
        )        
    }
    
    
      return (
        <div style={{textAlign:'center'}}>
            {/* { count_variants == 1 ? */}
                {/* // <LikeSlide question={questions[question_index]} onNext={next_q} /> */}
                <div className='PanelLikeCards'>
                    

                    <div>
                    {/* <link
                        href='https://fonts.googleapis.com/css?family=Damion&display=swap'
                        rel='stylesheet'
                    />
                    <link
                        href='https://fonts.googleapis.com/css?family=Alatsi&display=swap'
                        rel='stylesheet'
                    /> */}
                    <h1>Вы бы хотели работать с тем, что показано на экране?</h1>
                    <div className='cardContainer'>
                        {db.map((character, index) => (
                        <TinderCard
                            ref={childRefs[index]}
                            className='swipe'
                            key={character.file}
                            preventSwipe={['up','down']}
                            // swipeRequirementType={'position'}
                            swipeThreshold={300}
                            onSwipe={(dir) => swiped(dir, character, index)}
                            onCardLeftScreen={() => outOfFrame(character, index)}
                        >
                            <div
                            style={{ backgroundImage: 'url("' + character.file + '")' }}
                            className='card'
                            >
                            {/* <h3>{character.name}</h3> */}
                            {/* {JSON.stringify(character)} */}
                            </div>
                        </TinderCard>
                        ))}
                    </div>
                
                    <div className='buttons'>
                        <Button style={{fontSize:20}} disabled={!canSwipe} onClick={() => swipe('left')}>
                        <big style={{fontSize:30, position:'relative', bottom: -3}}>👎</big>&nbsp; Вряд ли... 
                        </Button>
                        {/* <Button disabled={!canGoBack} onClick={() => goBack()}>Undo swipe!</Button> */}
                        &nbsp;
                        &nbsp;
                        <Button style={{fontSize:20}} disabled={!canSwipe} onClick={() => swipe('right')}>
                        <big style={{fontSize:30, position:'relative', bottom: 2}}>👍</big>&nbsp; Да! 
                        </Button>
                    </div>
                
                    {/* <p>
                        Оцените изображение или смахните в сторону оценки
                    </p> */}
                
                    {/* {lastDirection ? (
                        <h2 key={lastDirection} className='infoText'>
                        You swiped {lastDirection}
                        </h2>
                    ) : (
                        <h2 className='infoText'>
                        Swipe a card or press a button to get Restore Card button visible!
                        </h2>
                    )} */}
                
                    {/* {JSON.stringify(question)} */}
                    {/* <br/> */}
                    {/* <br/> */}
                    {/* {JSON.stringify(db[currentIndex])} */}
                    {/* {JSON.stringify(window.fix_actual_question)} */}
                    {/* {JSON.stringify(window.fix_actual_question)} */}
                
                    </div>

                </div>
                {/* :
                <SelectSlide question={questions[question_index]} key={question_index} onNext={next_q} />
            } */}
            {/* <Button onClick={()=>onEnd([1,2,3])}>End</Button> */}
            {/* --- */}
            {/* {question_index} */}
            {/* <Debug json={answers} /> */}
            {/* <Debug json={questions[question_index]} /> */}
        </div>
      )
}


export default QuestionsPartLikes






// https://3djakob.github.io/react-tinder-card-demo/img/dinesh.jpg



function MyTinderWrapper ({questions=[], onNext=()=>{}}) {

}

// export default Advanced
