
import React, {useState} from 'react';

import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    // // Link,
    // useParams,
    // Redirect,
    useHistory
  } from "react-router-dom";

import StModel from './st_model' 

import axios from 'axios'

import { 
	// LoadingOutlined, DownloadOutlined, CameraOutlined, 
    // QrcodeOutlined 
    CameraFilled, 
} from '@ant-design/icons';

import  {
	Button,
} from '@sberdevices/plasma-ui';




function LogoHome() {
	let history = useHistory();
	return (
		<img src="sbersight_demo_logo.svg" 
				onClick={() => history.push('/') }
				style={{verticalAlign: 'middle', width: '90px', margin: '15px'}}/> 
	)
}


// function get_random_category(cats) {
//     return cats[ Math.floor( Math.random() * cats.length ) ].id;
// }


export default function Page() {

    // let category_style_list = []

    let category_style_list = [
        { title: 'Пользовательские', items: [], hide:true, custom_user_loaded: true },

        { title: 'Раз', items: [] },
        // { title: 'Два', items: [] },
        // { title: 'Три', items: [] },
        // { title: 'Раз', items: [] },
        // { title: 'Два', items: [] },
        // { title: 'Три', items: [] },
    ]

    for ( let i = 0; i <= 24; i++) {
        let cat_index = Math.floor( Math.random() * (category_style_list.length - 1) ) + 1
    //   category_style_list.push({
        category_style_list[ cat_index ].items.push({
            id: 'style'+i,
            filename: 'styles/peter2/' +i+'.jpg',
            userstyle: false,
            // category: get_random_category_id(categories)
            category_id: cat_index,
        })
    }
   
    
    return (
        <div>
            <div style={{textAlign:'center'}}>
				<LogoHome/> 
			</div>   

            <StModel
                startText={mi => <>
                    <img src="/peter_logo.png" height="200" onClick={() => mi.on_load_src_img_demo("/einstein.jpg")} />
					<h1>Лик Петра I</h1>
					<p>Искусственный интеллект покажет,<br /> как вы бы выглядели на месте Петра I.</p>
                    <br />
                    <Button onClick={() => mi.open_dialog('#srcimg')}>
						<CameraFilled /> &nbsp; Загрузить своё фото
					</Button>
                </>}
                defStyles={category_style_list}
                userStyles={false}
                compareMode={false}
                filenameForDownload="digital_peter_faceswap.jpg"
                onMagic={(style_info, src_img_blob) => {
                    console.log('onMagic', style_info)

                    // return;
                    
                    // let api_url = 'http://45.89.225.221:9101/transfer_with_parameters';
                    // let api_url = 'http://45.89.225.221:9101/peter_faceswap';
                    let api_url = 'http://45.89.225.221:9101/peter_faceswap_with_qr';
                    let qData = new FormData();
                    qData.append("original_img", src_img_blob);

                    let qParams = {
                        model_type: 'fast',
                        is_peter: true,
                        user_style: style_info.userstyle,
                    }
                    if (style_info.userstyle) {
                        qData.append("style_img", style_info.blob);
                    } else {
                        qParams['style_id'] = style_info.id
                    }
            
                    return axios.post(api_url, qData, {
                        // responseType: "arraybuffer",
                        params: qParams,
                    }).then(res => {
                        console.log('res', res)
                        return {
                            painted_img_url:            'http://45.89.225.221:9101/get_file?ref=' + res.data.img,
                            painted_img_url_with_logo:  'http://45.89.225.221:9101/get_file?ref=' + res.data.img_with_logo,
                        }
                    }, err => {
                        console.log('err', err)
                        return false
                    })
                }}
            ></StModel>

            <style>
                { `
                html, body { min-height: 100vh; } 
                
                div.badgeDone {
                    display: inline;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    line-height: 57px;
                    text-align: center;
                    /* margin: 53px; */
                    margin: 62px 5px;
                    padding: 0;
                }
                .footer {
                    text-align: center;
                    position: fixed;
                    z-index: 10;
                    bottom: 0;
                    left: 0;
                    right: 0;
                  }
                  
                ` }
            </style>


            <div className="footer">
                <small>powered by</small>
                <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
            </div>

        </div>
    )
}