


export default function Footer() {
    return (
        <div>
            <style>
                {`
                .footer {
                    text-align: center;
                    position: fixed;
                    z-index: 10;
                    bottom: 0;
                    left: 0;
                    right: 0;
                  }
                  
                ` }
            </style>


            <div className="footer">
                <small>powered by</small>
                <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
            </div>
        </div>
    )
}

