
import React from 'react';
import {
    Container, Row, Col, 
    // Cell, CellIcon,
    // Underline,
    // Header,
    Card,
    CardBody,
    // CardContent,
    CardMedia,
    // CardHeadline1,
    // CardParagraph1,
    // TextBoxBigTitle,
    // TextBoxBiggerTitle,
    // TextBoxSubTitle,
    CarouselGridWrapper,
    Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    // TextBox,
    Button,
    Tabs, TabItem,

    // CarouselSection,
    CarouselCol,
    // MusicCard,

    

    Badge,
    // useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';

 import {
    // IconApps,
    // IconCalendar,
    IconDone,
    // IconCameraVideo
 } from '@sberdevices/plasma-icons';

// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useParams,
//     useHistory
//   } from "react-router-dom";

  
import { CameraFilled } from '@ant-design/icons';

import './st_cards.css'


function StyleItemCard( props ) {
    let item = props.item
    // let history = useHistory();
    
    let active = props.active

    let className = 'st-card'
    if (active == item.id) className += ' active';
    // let handleClick = (id) => {
        //     history.push("/meetings/" + id)
        // }

    // let status = cardStatus(item)
        
    return (
        <Card
            // style={{ background: '#00000030' }}
            // style={{ background: 'url(/styles/'+item.filename+')' }}
            // style={{ background: 'url(/styles/style1.jpg)' }}
            tabIndex={1}
            outlined={true}
            scaleOnFocus={true}
            // onClick={()=>history.push("/meetings/" + item.id)}
            onClick={() => props.onClick()}

            className={className}

        >
            <CardBody>
                <CardMedia
                    // src={'/styles/' + item.filename}
                    placeholder={item.filename}
                    ratio={'1 / 1'}
                    // style={{height:'600px'}}
                    />
              
                { active == item.id ? <Badge text={ <IconDone/> } view={'primary'} className={'badgeDone'}  ></Badge> : '' }
                
            </CardBody>
        </Card>
    )
}



function CameraCard( props ) {
    // let item = props.item
    // let history = useHistory();
    
    // let active = props.active

    let className = 'st-card'
        
    return (
        <Card
            style={{ background: '#000000aa' }}
            // style={{ background: 'var(--plasma-colors-button-accent)' }}

            tabIndex={1}
            outlined={true}
            scaleOnFocus={true}
            // onClick={()=>history.push("/meetings/" + item.id)}
            onClick={() => props.onClick()}
            className={className}
        >
            <CardBody>
                <CardMedia
                    // src={'/styles/' + item.filename}
                    // placeholder={'styles/mini/' + item.filename}
                    ratio={'1 / 1'}
                    // style={{height:'600px'}}
                    />
                
                {/* { active == item.id ? <Badge text={ <IconDone/> } view={'primary'} className={'badgeDone'}  ></Badge> : '' } */}
                <CameraFilled className="cardCameraIcon" style={{marginTop: 4}}/>

                {/* <small className="cardCameraTitle">загрузить стиль</small> */}
                <small className="cardCameraTitle" style={{marginTop:6}}>загрузить</small>

            </CardBody>
        </Card>
    )
}


function StyleCorusel(props) {
    return (
        <CarouselGridWrapper>
            <Carousel as={Row} axis={'x'} scrollAlign={'start'} scrollSnapType={'mandatory'}
                style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', justifyContent: 'start', margin:'0 auto', background: '#ffffff0f',
                borderRadius: '25px', padding:0 }}
            >
                { props.camera ? 
                    <CarouselCol style={{width:'120px', padding:'7px'}} scrollSnapAlign={'start'}> 
                        <CameraCard onClick={() => props.onCameraClick()}/>
                    </CarouselCol> : ''
                }

                { props.list.map( (x,index) => (
                    <CarouselCol style={{width:'120px', padding:'7px'}} key={index} scrollSnapAlign={'start'}> 
                        <StyleItemCard item={x} active={props.active} onClick={() => props.onSelect(x)} />
                    </CarouselCol>
                ))}
            </Carousel>
        </CarouselGridWrapper>
    );
}


let MyTabs2 = ({items, index, onChange, style}) => {
    return (
        <CarouselGridWrapper>
            <Carousel as={Row} axis={'x'} scrollAlign={'center'} scrollSnapType={'mandatory'}
                style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem', justifyContent: 'start' }}
            >
                {items.map((_, i) => (
                    <CarouselCol size={'auto'} style={{padding:'10px 30px', userSelect: 'none', lineHeight:1, borderRadius:'100px', background: i === index ? 'var(--plasma-colors-button-accent)': 'none'}} 
                            key={i} scrollSnapAlign={'start'} onClick={() => onChange(i)}> 
                        {/* <StyleItemCard item={x} active={props.active} onClick={() => props.onSelect(x)} /> */}
                        <nobr style={{fontWeight:'bold'}}>{_.title_up}</nobr>
                        <br/>
                        <nobr><small style={{fontSize: '12px', lineHeight:1}}>{_.title_down}</small></nobr>
                    </CarouselCol>
                ))}
            </Carousel>
        </CarouselGridWrapper>
    );
}




let MyTabs = ({items, index, onChange, style}) => {
    return (
        <Tabs
            size={'l'}
            // view={'secondary'}
            view={'clear'}
            // fixedWidth={true}
            pilled={true}
            scaleOnPress={true}
            outlined={false}
            style={style}
        >
            {items.map((_, i) => (
                <TabItem
                    key={i}
                    isActive={i === index}
                    tabIndex={i}
                    // style={{opacity: _ === index ? 1: 0.4}}
                    style={{background: i === index ? 'var(--plasma-colors-button-accent)': 'none'}}
                    onClick={() => onChange(i)}
                >
                    {_.title_up}
                    <br/>
                    <small style={{fontWeight: 'normal'}}>{_.title_down}</small>
                </TabItem>
            ))}
        </Tabs>
    );
}



// function onlyUnique(value, index, self) {
//     return self.indexOf(value) === index;
// }



export default function StyleCard (props) {
    const [ index, setIndex ] = React.useState(0) 
    
    // let userStyles = props.list[0].items
    let userStyles = props.customlist
    let categories = props.list.filter(x => !x.hide)
    let presetStyles = categories[index] ? categories[index].items : []

    return (
        <Container>

            { categories.length > 1 ? <MyTabs2 
                // style={{marginLeft: 'calc(var(--tab-item-padding-y-reduced)*(-1))', margin: '0 auto'}} 
                items={categories} index={index} onChange={(i) => setIndex(i)} /> : '' }

            {/* <StyleCorusel list={props.list.filter((x,i) => i%(index == 'info' ? 2: 3))} active={props.active} camera={props.camera}  */}
            <StyleCorusel 
                list={[ ...userStyles, ...presetStyles ]} 
                active={props.active} 
                camera={props.camera} 
                onSelect={(e) => props.onSelect(e)}
                onCameraClick={(e) => props.onCameraClick(e)}
            />
        </Container>
    )
}