import { Button } from "@sberdevices/plasma-ui"
import { IconHouse } from "@sberdevices/plasma-icons"
import { //WarningOutlined, PlusSquareOutlined, FireOutlined, 
	LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled, QrcodeOutlined } from '@ant-design/icons';
import axios from "axios";
import { useEffect, useState } from "react";

import utils from './utils'


function video_blob_to_url(blob) {
	let urlCreator = window.URL || window.webkitURL;
	return urlCreator.createObjectURL( new Blob([blob], {type: "video/mp4"}) )
}



const VideoPlayer = ({id, url, onEnded, onTimeUpdate, loop}) => {
    if (url == '') return '---'
    return (
        <video id={id} className="elena_video" width="200" preload="auto" loop={loop} autoPlay playsInline 
            onEnded={(e) => onEnded(e)} onTimeUpdate={(e)=>onTimeUpdate(e)}> 
                <source src={url} type="video/mp4" />
        </video>
    )
}


const my_loader = (url = 'elena/end.mp4', onProgress = ()=>{}, onEnd = ()=>{}) => {
    let xhr = new XMLHttpRequest();


    xhr.onload = function() {
        if (xhr.status != 200) { // анализируем HTTP-статус ответа, если статус не 200, то произошла ошибка
            console.log(`Ошибка ${xhr.status}: ${xhr.statusText}`); // Например, 404: Not Found
        } else { // если всё прошло гладко, выводим результат
            console.log(`Готово – ${url}, получили ${xhr.response.length} байт`); // response -- это ответ сервера
            onEnd(xhr.response)
        }
    };
    xhr.onprogress = function(event) {
        if (event.lengthComputable) {
            // console.log(`Получено ${event.loaded} из ${event.total} байт`);
            onProgress( `Получено ${ Math.round(event.loaded / 10000) / 100 } из ${Math.round(event.total / 10000) / 100} Мб` )
        } else {
            // console.log(`Получено ${event.loaded} байт`); // если в ответе нет заголовка Content-Length
            onProgress( `Получено ${ Math.round(event.loaded / 10000) / 100} Мб` )
        }
    };
    xhr.onerror = function(e) {
        console.log(`Запрос не удался ${xhr.status}: ${xhr.statusText}`, xhr, e);
    };
    // xhr.responseType = 'arraybuffer';
    xhr.responseType = 'blob';
    xhr.open('GET', url);
    xhr.send();
}

export default function EthicsPage() {
    const [elena_status, set_elena_status] = useState(-1)
    const [model_ts, set_model_ts] = useState(0)
    const [status_ts, set_status_ts] = useState(0)
    const [my_preloading, set_my_preloading] = useState(0)

    // let iid = null

    const [video_wait_url, set_video_wait_url] = useState('')
    const [video_main_url, set_video_main_url] = useState('')
    const [video_end_url, set_video_end_url] = useState('')

    const [cover_img_url, set_cover_img_url] = useState('')
    const [unethics_cover_img_url, set_unethics_cover_img_url] = useState('')


    const [video_wait_status, set_video_wait_status] = useState('')
    const [video_main_status, set_video_main_status] = useState('')
    const [video_end_status, set_video_end_status] = useState('')


    useEffect(()=> {    
        console.log('preload')
        
        // fetch('elena/horizontal/wait.mp4')
        // // fetch('http://sberai.vispstudio.ru/elena/wait.mp4')
        //     .then( t => t.blob().then( b => {
        //         set_video_wait_url( video_blob_to_url(b) ) 
        //         // set_my_preloading()
        //     })) 

        // fetch('elena/horizontal/main.mp4')
        // // fetch('http://sberai.vispstudio.ru/elena/main.mp4')
        //     .then( t => t.blob().then( b => {
        //         set_video_main_url( video_blob_to_url(b) ) 
        //         // set_my_preloading()
        //     })) 

        // fetch('elena/end.mp4')
        // // fetch('http://sberai.vispstudio.ru/elena/end.mp4')
        //     .then( t => t.blob().then( b => {
        //         set_video_end_url( video_blob_to_url(b) ) 
        //         // set_my_preloading()
        //     })) 

        // my_loader('http://sberai.vispstudio.ru/elena/horizontal/wait.mp4', (str_status)=> {
        // my_loader('http://sberai.vispstudio.ru/elena/horizontal/wait.mp4', (str_status)=> {
        my_loader('http://sberai.vispstudio.ru/elena/horizontal/wait.mp4', (str_status)=> {
            set_video_wait_status(str_status)
        }, (blob) => {
            set_video_wait_url( video_blob_to_url(blob) ) 
        })
        
        // my_loader('http://sberai.vispstudio.ru/elena/horizontal/main.mp4', (str_status)=> {
        // my_loader('http://sberai.vispstudio.ru/elena/horizontal/main.mp4', (str_status)=> {
        my_loader('http://sberai.vispstudio.ru/elena/horizontal/main.mp4', (str_status)=> {
            set_video_main_status(str_status)
        }, (blob) => {
            set_video_main_url( video_blob_to_url(blob) ) 

        })

        // my_loader('http://sberai.vispstudio.ru/elena/end.mp4', (str_status)=> {
        my_loader('http://sberai.vispstudio.ru/elena/end.mp4', (str_status)=> {
            set_video_end_status(str_status)
        }, (blob) => {
            set_video_end_url( video_blob_to_url(blob) ) 
        })


    },[])


    useEffect(()=> {
        console.log('init')
        // let iid = setInterval(() => {
        //     get_status()
        // }, 1000)
        let iid = setInterval(get_status, 1000)
        return () => {
            clearInterval(iid)
        }
    },[elena_status, model_ts, status_ts, video_wait_url, video_main_url, video_end_url])

    const update_scene = (num, new_status_ts = 0, new_model_ts = false) => {

        // set_elena_status(3)
        
        if (new_model_ts && new_model_ts > model_ts) {
            set_model_ts(new_model_ts)

            console.log('update img cover', new_model_ts, model_ts)

            // fetch('http://45.89.225.221:9101/get_file?ref=elena/transferred_no_logo.jpg&ts=' + new_model_ts)
            fetch('http://45.89.225.221:9101/get_file?ref=elena/transferred_ethical_no_logo.jpg&ts=' + new_model_ts)
            // fetch('http://sberai.vispstudio.ru/elena/wait.mp4')
                .then( t => t.blob().then( b => {
                    set_cover_img_url( utils.img_blob_to_url(b) ) 
                    // set_my_preloading()
                })) 

            fetch('http://45.89.225.221:9101/get_file?ref=elena/transferred_unethical_no_logo.jpg&ts=' + new_model_ts)
            // fetch('http://sberai.vispstudio.ru/elena/wait.mp4')
                .then( t => t.blob().then( b => {
                    set_unethics_cover_img_url( utils.img_blob_to_url(b) ) 
                    // set_my_preloading()
                })) 
        } else {
            // console.log('update img cover NO', new_model_ts, model_ts)
        }


        // if (elena_status == num ) {
        if (new_status_ts > status_ts ) {
            // console.log('update_scene go', elena_status, num)
            console.log('update_scene go', new_status_ts, status_ts)
        } else {
            console.log('update_scene block', status_ts, new_status_ts, elena_status, num)
            return
        }

        if ( new_status_ts) {
            set_status_ts(new_status_ts)
        }

        set_elena_status(num)

        // return

        let v_wait = document.getElementById('v-wait');
        let v_main = document.getElementById('v-main');
        let v_end = document.getElementById('v-end');

        if (!v_wait) return;
        if (!v_main) return;
        if (!v_end) return;

        v_wait.pause()
        v_main.pause()
        v_end.pause()
        switch(num) {

            case 1: // Рассказ
                v_wait.currentTime = 0
                v_main.currentTime = 0
                v_end.currentTime = 0
                v_main.play();
                v_main.muted = false;
                break;
            case 2: // Портрет готов
                v_wait.currentTime = 0
                v_main.currentTime = 66.0
                v_end.currentTime = 0
                v_main.play();
                v_main.muted = false;
                break;
            case 3: // Финальный стоп-кадр
                v_wait.currentTime = 0
                v_main.currentTime = 0
                v_end.currentTime = 0
                break;
            case 4: // Пока
                v_wait.currentTime = 0
                // v_main.currentTime = 0
                v_end.currentTime = 0
                // v_end.play();
                // v_end.muted = false;
                v_main.currentTime = 75.7
                v_main.play();
                v_main.muted = false;
                break;

            case 0: // Ждем
            default:
                v_wait.currentTime = 0
                v_main.currentTime = 0
                v_end.currentTime = 0
                v_wait.play();
                break;
        }
    }

    const get_status = () => {
        if ( video_wait_url == ''  || video_main_url == '' || video_end_url == '' ) {
            return
        }
        return axios.get('http://45.89.225.221:9101/get_status_elena').then(res => {
            // if (elena_status == -1) {
            //     setTimeout(()=>{
            //         update_scene(0)
            //     }, 500)
            // }


            // console.log('res', res)
            let new_status = parseInt( res.data.status_elena );
            // if (status != new_status ) {

                // console.log(status, new_status)

                // setStatus(new_status)
                update_scene( new_status, 
                    new Date(res.data.status_timestamp).getTime(),
                    new Date(res.data.model_timestamp).getTime(),
                )
            // }
            // set_model_ts(res.data.model_timestamp)
            // set_status_ts(res.data.status_timestamp)
        }, err => {
            console.log('err', err)
            return false
        })
    }
    const on_timeupdate = (e) => {
        if ( elena_status == 1 && e.target.currentTime >= 66.1 ) {
            // update_scene(2)
            set_elena_status(2)
        }
        // console.log(e.target.currentTime)
    }

    if ( video_wait_url == ''  || video_main_url == '' || video_end_url == '') {
        return (
            <div style={{padding:'50px'}}>
                <br/>
                Видео 0: {video_wait_url == '' ? 'Загрузка... ' + video_wait_status : 'ok'}
                <br/>
                Видео 1: {video_main_url == '' ? 'Загрузка... ' + video_main_status : 'ok'}
                <br/>
                Видео 2: {video_end_url == '' ? 'Загрузка... ' + video_end_status : 'ok'}
                <br/>
            </div>
        )
    }

    return (
        <div className={"EthicsPage status-" + elena_status} onClick={() => update_scene(elena_status, status_ts + 1)}>

            {/* <div className="panel" >
                <Button view={"secondary"} size="s" onClick={() => update_scene(elena_status, status_ts + 1)} >0</Button>
                <Button view={elena_status == 0 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(0, status_ts + 1)} >0</Button>
                <Button view={elena_status == 1 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(1, status_ts + 1)} >1</Button>
                <Button view={elena_status == 2 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(2, status_ts + 1)} >2</Button>
                <Button view={elena_status == 3 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(3, status_ts + 1)} >3</Button>
                <Button view={elena_status == 4 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(4, status_ts + 1)} >4</Button>
                &nbsp;
                {model_ts}
            </div> */}

            {/* {video_wait_url} */}

            {/* <video controls width="200" preload="auto" loop autoPlay playsInline> 
                <source src={video_wait_url} type="video/mp4" />
            </video> */}
            {/* {video_player(video_wait_url)} */}
                {/* <source src="elena/wait.mp4" type="video/mp4" /> */}

            {/* <video width="200" preload="auto" loop autoPlay playsInline> 
                <source src="elena/wait.mp4" type="video/mp4" />
            </video> */}
            


            <div className="wrapper">
                <div className="loader_img_box" style={{
                            // backgroundImage: 'url(http://45.89.225.221:9101/get_file?ref=elena/transferred_no_logo.jpg&ts=' + model_ts + ')'
                            backgroundImage: 'url(' + cover_img_url + ')'
                        }} />
                <div className="big_cover">
                    <h1>Ваш портрет<span>,</span> написанный Этичным ИИ</h1>
                    <h2>Неэтичный ИИ писал бы так</h2>
                    <div className="img_box" style={{
                        backgroundImage: 'url(' + cover_img_url + ')'
                    }} />
                    <div className="img_box_unethics" style={{
                        backgroundImage: 'url(' + unethics_cover_img_url + ')'
                    }} />
                    <img className="qr_box" src="/qr_share_elena.gif" />
                </div>
                <div className="mini_cover">
                    <div className="img_box" style={{
                        // backgroundImage: 'url(http://45.89.225.221:9101/get_file?ref=elena/transferred_no_logo.jpg&ts=' + model_ts + ')'
                        backgroundImage: 'url(' + cover_img_url + ')'
                    }} />
                    <div className="qr_box" />
                </div>
                {/* controls */}
                {/* <video id="v-wait" className="elena_video" 
                    preload="auto" loop autoPlay playsInline> 
                        <source src="elena/wait.mp4" type="video/mp4" />
                </video>
                <video id="v-main" autoPlay muted playsInline className="elena_video" 
                    preload="auto" 
                    onEnded={() => update_scene(3, status_ts + 1)} onTimeUpdate={(e)=>on_timeupdate(e)}> 
                        <source src="elena/main.mp4" type="video/mp4" />
                </video>
                <video id="v-end" autoPlay muted playsInline className="elena_video" 
                    preload="auto" 
                    onEnded={() => update_scene(0, status_ts + 1)}> 
                        <source src="elena/end.mp4" type="video/mp4" />
                </video> */}
                <VideoPlayer id="v-wait" url={video_wait_url} onEnded={() => {}} onTimeUpdate={()=>{}} loop={true} />
                <VideoPlayer id="v-main" url={video_main_url} onEnded={() => update_scene(3, status_ts + 1)} onTimeUpdate={(e)=>on_timeupdate(e)} loop={false} />
                <VideoPlayer id="v-end" url={video_end_url} onEnded={() => update_scene(0, status_ts + 1)} onTimeUpdate={()=>{}} loop={false} />
            </div>





            <style>{`
                .panel {
                    position:absolute;
                    left:0;
                    top:0;
                    z-index:1
                }
                .elena_video {
                    width: 100%;
                    display: none;
                }
                .EthicsPage { 
                    line-height:0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100vh;
                }
                .loader_img_box {
                    width:10px;
                    height:10px;
                    top:0;
                    right:0;
                    z-index:100;
                    position: absolute;
                    opacity: 0.1;
                }
                .mini_cover .img_box {
                    width: 50%;
                    height: 70%;
                    position:absolute;
                    left:30%;
                    background-size:cover;

                    width: 37%;
                    height: 80%;
                    position: absolute;
                    left: 50%;
                    top: 5%;
                    background-size: cover;
                    background-position: center;
                }
                .mini_cover .qr_box {
                    width: 20%;
                    height: 35%;
                    position: absolute;
                    right: 3%;
                    bottom: 5%;
                    background: #fff url(/qr_share_elena.gif) no-repeat center center;
                    background-size: contain;
                }
                .mini_cover {
                    display: none;
                }
                .big_cover .img_box {
                    width:100%;
                    height: 100%;
                    position:absolute;
                    left:0;
                    bottom:0;
                    background-size:cover;
                }
                .big_cover .img_box_unethics {
                    width:50%;
                    height: 50%;
                    position:absolute;
                    left:-53%;
                    bottom:0;
                    background-size:cover;
                    /*filter: invert(1) grayscale(1);*/
                }
                .big_cover .qr_box {
                    width: min(30vw, 30vh);
                    position:fixed;
                    right:4%;
                    top:3%;
                    position:absolute;
                    left: 97%;
                    /* left: 103%; */
                }
                .big_cover h1 {
                    width: min(30vw, 30vh);
                    position:fixed;
                    position:absolute;
                    right:103%;
                    /* text-align:right; */
                    top:3%;
                    line-height:1.2;
                    font-size: 40px;
                    z-index:1;
                    width: 50%;
                }
                .big_cover h1 span {
                    margin-right: -10px;
                }
                .big_cover h2 {
                    width: min(30vw, 30vh);
                    position:fixed;
                    position:absolute;
                    -left:4%;
                    left: -53%;
                    bottom:50%;
                    line-height:1;
                    font-size:15px;
                }
                .big_cover {
                    display: none;
                }
                .wrapper {
                    /* width:min(100vw, 75vh); */
                    width:min(100vw, 177.7vh);
                    /* height:min(133.3vw, 100vh); */
                    height:min(56.2vw, 100vh);                  
                    position:relative;
                }
                
                .status-3 .wrapper {
                    /* background: yellow; */
                    width:min(100vw, 75vh);
                    height:min(133.3vw, 100vh);                 
                    position:relative;
                }

                .status-0 #v-wait { display: block; }
                .status-1 #v-main { display: block; }
                .status-2 #v-main { display: block; }
                .status-2 .mini_cover { display: block; }
                .status-3 .big_cover { display: block; }
                /* .status-4 #v-end { display: block; } */
                .status-4 #v-main { display: block; }
            `}</style>

        </div>
    )
}