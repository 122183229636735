import { Badge, Button, Card, CardBody, CardBody1, CardContent, CardHeadline2, CardHeadline3, CardMedia, TextField } from "@sberdevices/plasma-ui";
import { Container } from "@sberdevices/plasma-ui";
import { Row, Col } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
import utils from "../utils";


import { //WarningOutlined, PlusSquareOutlined, FireOutlined, 
	LoadingOutlined, 
    // DownloadOutlined, CameraOutlined, CameraFilled, QrcodeOutlined 
} from '@ant-design/icons';
import { useHistory } from "react-router";
import Debug from "../../elem/Debug";







function API_Exec(model = {}, input_data = { text: "галактика в огне" } ) {
    return axios.post('http://45.89.225.221:8111/fbmp/api/v1/proxy', {
        "model_endpoint_id": model.id,
        "data": input_data
    }).then(res => {
        return res.data
    })

    // {
    //     "model_endpoint_id": 1,
    //     "data": {
    //         "text": "галактика в огне"
    //     }
    // }
}

function API_LoadModelList() {
    return axios.get('http://45.89.225.221:8111/fbmp/api/v1/models').then(res => {
        return res.data.models
    })

    // return Promise.resolve(
    //     [
    //         {
    //             "created": "Tue, 23 Nov 2021 16:41:03 GMT",
    //             "credentials": {
    //                 "credentials_id": 1,
    //                 "credentials_login": "potanin.m.st@sberbank.ru",
    //                 "credentials_password": "Andyhaid181!",
    //                 "credentials_url": "https://api.aicloud.sbercloud.ru/public/v2/auth"
    //             },
    //             "default_params": {},
    //             "description": "Generate picture via text",
    //             "endpoint": "https://api.aicloud.sbercloud.ru/public/v2/inference/v1/predict/kfserving-1636384016/kfserving-1636384016/",
    //             "headers": {
    //                 "x-api-key": "26ca6b81-05a8-4bb7-bf1b-6a8a521a3419",
    //                 "x-workspace-id": "c93ddf49-514a-4323-a39f-843488615cc0"
    //             },
    //             "id": 1,
    //             "input_fields": [
    //                 {
    //                     "datasource": [],
    //                     "description": "Input text to generate image",
    //                     "field_type": "text",
    //                     "id": 1,
    //                     "is_required": true,
    //                     "placeholder": "TEXT",
    //                     "proxy_as": "text"
    //                 }
    //             ],
    //             "method": "POST",
    //             "model_name": "Peter handjob",
    //             "output_fields": [
    //                 {
    //                     "description": "Input text to generate image",
    //                     "field_type": "text",
    //                     "id": 1,
    //                     "placeholder": "TEXT",
    //                     "proxy_as": "text"
    //                 }
    //             ],
    //             "primary_image_url": "http://sberai.site:8110/imgcloud/qr/1636553508/screen.jpg",
    //             "proxy_pass_config": "default_proxy",
    //             "proxy_pass_config_id": 1,
    //             "secondary_image_url": "http://sberai.site:8110/imgcloud/qr/1636553508/screen.jpg",
    //             "tags": [],
    //             "updated": "Tue, 23 Nov 2021 16:41:03 GMT"
    //         }
    //     ]
    // )
}


function imgBase64_to_imgUrl(imgBase64) {
    const getMimeType = (blob) => {
        if ( blob.indexOf('PNG') > -1 ) return 'image/png'
        if ( blob.indexOf('JFIF') > -1 ) return 'image/jpeg'
        if ( blob.indexOf('GIF') > -1 ) return 'image/gif'
        if ( blob.indexOf('SVG') > -1 || blob.indexOf('svg') > -1 ) return 'image/svg+xml'
    }
    if (imgBase64 === null || !imgBase64) { 
        // return Promise.reject() 
        return '';
    }
    return 'data:' + getMimeType(atob(imgBase64)) + ';base64,' + imgBase64
}




// function ModelCard_1_1({model, onSelect=()=>{}}) {
//     const histoty = useHistory();
//     return (
//         // <Card onClick={() => onSelect(model)} style={{marginBottom:20}} 
//         <Card onClick={() => histoty.push('/fb/' + model.id)} style={{marginBottom:20}} 
//             tabIndex={1}
//             outlined={true}
//             scaleOnFocus={true}
//             scaleOnHover={true}
//         >
//             <CardBody>
//                 <CardMedia
//                     src={model.primary_image_url}
//                     // placeholder={model.primary_image_url}
//                     ratio={'1/1'}
//                     // ratio={'16/9'}
//                     // ratio={'4/3'}
//                     />
//                 <CardContent cover={true}>
//                     {/* <img src={model.primary_image_url} width={100} /> */}
//                     {/* <img src={model.secondary_image_url} width={100} /> */}
//                     <CardHeadline2>{model.model_name}</CardHeadline2>
//                     <CardBody1 style={{marginBottom: 9, marginTop: 5}}>{model.description}</CardBody1>
//                     {/* <p>{JSON.stringify(model.tags)}</p> */}

//                     <div>
//                         {/* {['NLP', 'CNN'].map((x,i) =>  */}
//                         {model.tags.map((x,i) => 
//                             <Badge key={i} text={ x } view="primary" style={{display:'inline', marginRight:'5px', background: ''}} /> 
//                         )} 
//                     </div>
                    
//                 </CardContent>
//             </CardBody>
//         </Card>
//     )
// }
// function ModelCard_2_1({model, onSelect=()=>{}}) {
//     const histoty = useHistory();
//     return (
//         // <Card onClick={() => onSelect(model)} style={{marginBottom:20}} 
//         <Card onClick={() => histoty.push('/fb/' + model.id)} style={{marginBottom:20}} 
//             tabIndex={1}
//             outlined={true}
//             scaleOnFocus={true}
//             scaleOnHover={true}
//         >
//             <CardBody>
//                 <CardMedia
//                     src={model.primary_image_url}
//                     // placeholder={model.primary_image_url}
//                     ratio={'2/1'}
//                     // ratio={'16/9'}
//                     // ratio={'4/3'}
//                     />
//                 <CardContent cover={true}>
//                     {/* <img src={model.primary_image_url} width={100} /> */}
//                     {/* <img src={model.secondary_image_url} width={100} /> */}
//                     <CardHeadline2>{model.model_name}</CardHeadline2>
//                     <CardBody1 style={{marginBottom: 9, marginTop: 5}}>{model.description}</CardBody1>
//                     {/* <p>{JSON.stringify(model.tags)}</p> */}

//                     <div>
//                         {/* {model.tags.map((x,i) =>  */}
//                         {['NLP', 'CNN'].map((x,i) => 
//                             <Badge key={i} text={ x } view="primary" style={{display:'inline', marginRight:'5px', background: ''}} /> 
//                         )} 
//                     </div>
                    
//                 </CardContent>
//             </CardBody>
//         </Card>
//     )

// }

function ModelCard_N({model, onSelect=()=>{}, h = 200, ...rest}) {
    const histoty = useHistory();

    if (!model) return ''

    return (
        // <Card onClick={() => onSelect(model)} style={{marginBottom:20}} 
        // <Card onClick={() => model.id == 2 ? histoty.push('/style-transfer') : histoty.push('/fb/' + model.id)} 
        <Card onClick={() => histoty.push('/fb/' + model.id)} 
            tabIndex={1}
            outlined={true}
            scaleOnFocus={true}
            scaleOnHover={true}
            style={{height:h, marginBottom:20}}
            {...rest}
        >
            <CardBody style={{ background:'url('+model.primary_image_url+') center center', backgroundSize: 'cover'}}>
                <CardMedia
                    // src={model.primary_image_url + '?123'}
                    height={200}
                    // placeholder={model.primary_image_url}
                    
                    // ratio={'1.5/1'}
                    // ratio={'16/9'}
                    // ratio={'4/3'}
                    />
                <CardContent cover={true}>
                    {/* <img src={model.primary_image_url} width={100} /> */}
                    {/* <img src={model.secondary_image_url} width={100} /> */}
                    <CardHeadline2>{model.model_name}</CardHeadline2>
                    <CardBody1 style={{marginBottom: 9, marginTop: 5}}>{model.description}</CardBody1>
                    {/* <p>{JSON.stringify(model.tags)}</p> */}

                    <div>
                        {/* {['NLP', 'CNN'].map((x,i) =>  */}
                        {model.tags.map((x,i) => 
                            <Badge key={i} text={ x } view="primary" style={{display:'inline', marginRight:'5px', background: ''}} /> 
                        )} 
                    </div>
                    
                </CardContent>
            </CardBody>
        </Card>
    )

}



export default function ModelsPage() {
    const [models, set_models] = useState([])
    const [active_model, set_active_model] = useState({})
    const [exec_results, set_exec_results] = useState({})
    const [exec_results_prepare, set_exec_results_prepare] = useState([])
    const [exec_inputs, set_exec_inputs] = useState({})
    const [exec_inputs_full, set_exec_inputs_full] = useState({})
    const [result_img, set_result_img] = useState('')
    
    const [exec_loading, set_exec_loading] = useState(false)

    const loadModels = () => {
        API_LoadModelList().then( set_models )
    }

    const prepare_results = (input_data, results ) => {
        return active_model.output_fields.map((x,i) => (
            {...x, result: results[x.proxy_as], input: { ...input_data } }
        ))
    }
    
    const onExec = (input_data = {}, inputs_full_data = {}) => {
        if ( exec_loading == true) return;
        set_exec_inputs(input_data)
        set_exec_inputs_full(inputs_full_data)
        set_exec_loading( true )
        API_Exec(active_model, input_data).then( res => {
            // set_result_img( imgBase64_to_imgUrl( res.image ) )
            set_exec_results( res )
            set_exec_results_prepare( prepare_results( inputs_full_data, res )  )
            set_exec_loading( false )
        } )
    }

    useEffect(()=> {
        loadModels()
    }, [])

    return (
        // <Container style={{background:'url(cfb_bg.jpg) center top', backgroundSize: 'cover'}}>
        <Container>
            <style>
                {`
                    body { background: url(cfb_bg.jpg) center top; background-size: cover;  }
                `}
            </style>
            <div>

                {/* <h1>ModelsPage</h1> */}
                <br/>
                <br/>
                <div style={{display:'flex'}}>
                    <img src={'/sberai_logo.svg'} width="150" style={{marginRight:40}} />
                    <img src={'/airi_logo.png'} width="150" />
                </div>
                <br/>
                <br/>
                <br/>
                <h1>Fusion Brain</h1>
                <p>Мультимодальная мультитаск архитектура Fusion Brain</p>
                <br/>

                <Row gutter={[20, 20]}>
                    

                        {/* <Button size={'s'} onClick={()=>{load()}}>load models</Button>
                        <br/>
                        <br/> */}
                        <Col sm={12} md={8} lg={8}>
                            {/* <ModelCard_N h={420}  model={models.find(x=>x.id==7)} onSelect={()=>set_active_model(models.find(x=>x.id==7))} />  */}
                            <ModelCard_N h={420}  model={models.find(x=>x.id==1)} onSelect={()=>set_active_model(models.find(x=>x.id==1))} /> 
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            {/* <ModelCard_N style={{marginBottom:20, height:270}} model={models.find(x=>x.id==8)} onSelect={()=>set_active_model(models.find(x=>x.id==8))} />  */}
                            <ModelCard_N style={{marginBottom:20, height:270}} model={models.find(x=>x.id==2)} onSelect={()=>set_active_model(models.find(x=>x.id==2))} /> 
                            <ModelCard_N h={270} model={models.find(x=>x.id==3)} onSelect={()=>set_active_model(models.find(x=>x.id==3))} /> 
                        </Col>
                        <Col sm={12} md={8} lg={8}>
                            <ModelCard_N h={200}  model={models.find(x=>x.id==6)} onSelect={()=>set_active_model(models.find(x=>x.id==6))} /> 
                            <Row gutter={[20, 20]}>
                                <Col span={12}>
                                    <ModelCard_N style={{marginBottom:20, height:200}} model={models.find(x=>x.id==4)} onSelect={()=>set_active_model(models.find(x=>x.id==4))} /> 
                                </Col>
                                <Col span={12}>
                                    <ModelCard_N h={200}  model={models.find(x=>x.id==5)} onSelect={()=>set_active_model(models.find(x=>x.id==5))} /> 
                                </Col>
                            </Row>
                        </Col>

                    </Row>
                <br/>
                <br/>
                {/* <Row gutter={[20]}>
                    { models.map((x,i)=> 
                        <Col sm={12} md={8} lg={6} key={i}>
                            <ModelCard_N  model={x} onSelect={set_active_model} /> 
                        </Col>
                    )}
                </Row> */}

                {/* <Debug json={models} /> */}

            </div>
        </Container>
    )
}