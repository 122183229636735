import { useEffect, useState } from "react"


function ImgWithDots({src, dots, style}) {

    const [ scale, setScale ] = useState(1)
    const [ img, setImg ] = useState(null)

    const on_load = (e) => {
        // let img = document.getElementById('img')
        let img = e.target

        setImg(img)
        // console.log(img.width, img.height, '-', img.clientWidth, img.naturalWidth)

        setScale( img.clientWidth / img.naturalWidth )
        // console.log(e)
    }

    const on_resize = (e) => {
        console.log('on_resize')
        if (img) {
            setScale( img.clientWidth / img.naturalWidth )
        }
    }

    useEffect(() => {
        window.addEventListener('resize', on_resize, true);

        return () => {
            window.removeEventListener('resize', on_resize)
        }
    })

    return (
        <div className="img-with-dots" style={style}>
            {dots.map((d,i)=> (
                <div className="dot" key={i} style={{left:(d.x*scale)+'px', top:(d.y*scale)+'px'}}>{d.node}</div>
            ))}
            <img src={src} onLoad={ on_load } />

            <style>
                {`
                .img-with-dots { position: relative; display:flex; align }
                .img-with-dots img { max-height: 80vh; max-width:100%; width: auto; }
                .dot { position: absolute; background: black; }
                `}
            </style>
        </div>
    )
}

let dots = [
    {x:100, y:200, node:'A'},
    {x:200, y:300, node:'B'},
    {x:300, y:500, node:'C'},
]

export default function FoodPage() {


    return (
        <div>
            FoodPage

            <ImgWithDots src="belka.jpg" dots={dots} style={{width:'100%', height:'100%'}} />
        </div>
    )
}