import { Button } from '@sberdevices/plasma-ui'
import React, { useState, useMemo, useRef } from 'react'
// import TinderCard from '../react-tinder-card/index'
import TinderCard from 'react-tinder-card'
// import TinderCard from './re-react-tinder-card'
import './TinderCards.css'

// https://3djakob.github.io/react-tinder-card-demo/img/dinesh.jpg

const db = [

  // {
  //   name: 'Richard Hendricks',
  //   url: 'https://3djakob.github.io/react-tinder-card-demo/img/richard.jpg'
  // },
  // {
  //   name: 'Erlich Bachman',
  //   url: 'https://3djakob.github.io/react-tinder-card-demo/img/erlich.jpg'
  // },
  // {
  //   name: 'Monica Hall',
  //   url: 'https://3djakob.github.io/react-tinder-card-demo/img/monica.jpg'
  // },
  // {
  //   name: 'Jared Dunn',
  //   url: 'https://3djakob.github.io/react-tinder-card-demo/img/jared.jpg'
  // },
  // {
  //   name: 'Dinesh Chugtai',
  //   url: 'https://3djakob.github.io/react-tinder-card-demo/img/dinesh.jpg'
  // },

  {
    name: 'Richard Hendricks',
    url: 'http://45.89.225.221:8110/part1/01-01.jpg'
  },
  {
    name: 'Erlich Bachman',
    url: 'http://45.89.225.221:8110/part1/01-02.jpg'
  },
  {
    name: 'Monica Hall',
    url: 'http://45.89.225.221:8110/part1/02-01.jpg'
  },
  {
    name: 'Jared Dunn',
    url: 'http://45.89.225.221:8110/part1/02-02.jpg'
  },
  {
    name: 'Dinesh Chugtai',
    url: 'http://45.89.225.221:8110/part1/03-02.jpg'
  }
]


window.fix_actual_lastDirection = ''
window.fix_actual_onNext = ()=>{}
window.fix_actual_question = {}

function Advanced ({questions=[], question={}, onNext=()=>{}}) {

  let db = questions.reverse().map(x => ({
    name: x?.variants?.[0].file,
    url: '/who' + x?.variants?.[0].file,
    value: x?.variants?.[0].value
  }))

  const [currentIndex, setCurrentIndex] = useState(db.length - 1)
  const [lastDirection, setLastDirection] = useState()

  window.fix_actual_lastDirection = lastDirection
  window.fix_actual_onNext = onNext
  window.fix_actual_question = question
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex)

  const childRefs = useMemo(
    () =>
      Array(db.length)
        .fill(0)
        .map((i) => React.createRef()),
    []
  )

  // то что показываю сейчас, текст вопроса без ответа
  const updateCurrentIndex = (val) => {
    setCurrentIndex(val)
    console.log('updateCurrentIndex', val, db[val])
    currentIndexRef.current = val
  }

  const canGoBack = currentIndex < db.length - 1

  const canSwipe = currentIndex >= 0

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    setLastDirection(direction)
    updateCurrentIndex(index - 1)

    console.log('end swiped', direction, index)

    // setTimeout(() => {

    //     // if ( lastDirection == 'left') {
    //     if ( window.fix_actual_lastDirection == 'left') {
    //         // onNext( { ...questions[index]?.variants?.[0], value: 0 } )
    //         window.fix_actual_onNext( { ...window.fix_actual_question?.variants?.[0], value: 0 } )
    //         // } else if ( lastDirection == 'right' ) {
    //     } else if ( window.fix_actual_lastDirection == 'right' ) {
    //         // onNext( questions[index]?.variants?.[0] )
    //         window.fix_actual_onNext( window.fix_actual_question?.variants?.[0] )
    //     }
    // }, 500)
          
  }
  
  const outOfFrame = (name, idx) => {

    console.log('outOfFrame',name)
    // if ( lastDirection == 'left') {
    if ( window.fix_actual_lastDirection == 'left') {
      window.fix_actual_onNext( { ...questions.find(x=>x.variants[0].file == name)?.variants?.[0], value: 0 } )
      // onNext( { ...questions[index]?.variants?.[0], value: 0 } )
      // window.fix_actual_onNext( { ...questions[index]?.variants?.[0], value: 0 } )
      // window.fix_actual_onNext( { ...window.fix_actual_question?.variants?.[0], value: 0 } )
    // } else if ( lastDirection == 'right' ) {
    } else if ( window.fix_actual_lastDirection == 'right' ) {
      window.fix_actual_onNext( questions.find(x=>x.variants[0].file == name)?.variants?.[0] )
      // window.fix_actual_onNext( questions[index]?.variants?.[0] )
      // window.fix_actual_onNext( window.fix_actual_question?.variants?.[0] )
      // window.fix_actual_onNext( window.fix_actual_question?.variants?.[0] )
    }

    // console.log(`${name} (${idx}) left the screen !`, window.fix_actual_lastDirection, currentIndexRef.current)
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  }

  const swipe = async (dir) => {
    // console.log('asinc swipe', dir)
    if (canSwipe && currentIndex < db.length) {
      try {
        await childRefs?.[currentIndex]?.current?.swipe(dir) // Swipe the card!
      } catch (e) {
        console.log(e)
        console.log(currentIndex, childRefs?.[currentIndex], childRefs?.[currentIndex]?.current, childRefs?.[currentIndex]?.current?.swipe(dir))
      }
    }
  }

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return
    const newIndex = currentIndex + 1
    updateCurrentIndex(newIndex)
    await childRefs[newIndex].current.restoreCard()
  }

  return (
    <div>
      {/* <link
        href='https://fonts.googleapis.com/css?family=Damion&display=swap'
        rel='stylesheet'
      />
      <link
        href='https://fonts.googleapis.com/css?family=Alatsi&display=swap'
        rel='stylesheet'
      /> */}
      {/* <h1>React Tinder Card</h1> */}
      <div className='cardContainer'>
        {db.map((character, index) => (
          <TinderCard
            ref={childRefs[index]}
            className='swipe'
            key={character.name}
            preventSwipe={['up','down']}
            // swipeRequirementType={'position'}
            swipeThreshold={300}
            onSwipe={(dir) => swiped(dir, character.name, index)}
            onCardLeftScreen={() => outOfFrame(character.name, index)}
          >
            <div
              style={{ backgroundImage: 'url("' + character.url + '")' }}
              className='card'
            >
              {/* <h3>{character.name}</h3> */}
              {JSON.stringify(character)}
            </div>
          </TinderCard>
        ))}
      </div>

      <div className='buttons'>
        <Button disabled={!canSwipe} onClick={() => swipe('left')}>Скучно! &nbsp;<big style={{fontSize:30}}>👎</big></Button>
        {/* <Button disabled={!canGoBack} onClick={() => goBack()}>Undo swipe!</Button> */}
        &nbsp;
        &nbsp;
        <Button disabled={!canSwipe} onClick={() => swipe('right')}>Нравится! &nbsp;<big style={{fontSize:30}}>👍</big></Button>
      </div>

      <p>
        Оцените изображение или смахните в сторону оценки
      </p>

      {/* {lastDirection ? (
        <h2 key={lastDirection} className='infoText'>
          You swiped {lastDirection}
        </h2>
      ) : (
        <h2 className='infoText'>
          Swipe a card or press a button to get Restore Card button visible!
        </h2>
      )} */}

      {JSON.stringify(question)}
      {/* {JSON.stringify(window.fix_actual_question)} */}
      {/* {JSON.stringify(window.fix_actual_question)} */}

    </div>
  )
}

export default Advanced