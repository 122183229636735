

import React, { useState } from 'react';

import {
    // Container, Row, Col, 
    // Cell, CellIcon, CellListItem,
    // Header,

    // HeaderRoot,
    // HeaderBack,
    // HeaderLogo,
    // HeaderTitleWrapper,
    // HeaderTitle,
    // HeaderSubtitle,
    // HeaderContent,

    Card,
    CardBody,
    CardContent,
    CardMedia,
    // CardHeadline1,
    // CardParagraph1,
    TextBoxBigTitle,
    TextBoxBiggerTitle,
    // TextBoxSubTitle,
    // TextBoxTitle,
    // Badge,
    // Tabs, TabItem,
    // Display2,
    // CarouselGridWrapper,
    // Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    Button,
    // ActionButton,
    // useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    Underline,
    // Spinner
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';


import { Modal } from 'antd';
import { QrcodeOutlined, CameraOutlined, LoadingOutlined } from '@ant-design/icons';

import QRCode from "react-qr-code"; 
import axios from 'axios';
import html2canvas from 'html2canvas';


// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useParams,
//     useHistory
//   } from "react-router-dom";

// import './rec.css'
// import kilib from '../../dict/ki.js' 

const coverImgUrl = '/320_320_0.jpg';


//
// AboutKI 
//

function PlaySnapshotEffect() {
    return new Promise((resolve, reject) => {
        document.getElementsByTagName('html')[0].classList.add("white-flash-snapshot");
        setTimeout(()=> {
            document.getElementsByTagName('html')[0].classList.remove("white-flash-snapshot");
            resolve()
        }, 100)
    })
}

function SendScreenshotOnServer(file_blob) {
    let qData = new FormData();
    qData.append("file", file_blob);
    // console.log('file_blob', file_blob)
    // return axios.post('http://sberai.site:8109/prof/api/v1/file', qData).then(res => res.data.result.file)
    // return axios.post('https://sberai.site:8109/prof/api/v1/file', qData).then(res => res.data.result.file)
    // return axios.post('https://45.89.225.221:8110/prof/api/v1/file', qData).then(res => res.data.result.file)
    return axios.post('https://sberai.site:8109/prof/api/v1/file', qData).then(res => res.data.result.file)
}

export default function QRmodalSnapshot({b64='', onBeforeShot=()=>{}, onAfterShot=()=>{}}) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isMoreVisible, setIsMoreVisible] = useState(false);
    const [file_url, set_file_url] = useState(null);
    // const [width, setWidth] = useState('40%');
    const [width, setWidth] = useState('600px');

    const showMore = (e) => {
        width != '40%' ? setWidth('40%') : setWidth('70%')
        console.log(showMore, isMoreVisible, width)
        setIsMoreVisible(true);
        e.stopPropagation()        
    }
    const hideMore = (e) => {
        setIsMoreVisible(false);
        e.stopPropagation()        
    }
  
    const showModal = (e) => {
        set_file_url(null)
        e.stopPropagation();
        onSaveScreen()
    };
  
    const handleOk = (e) => {
      setIsModalVisible(false);
      e.stopPropagation()
    };
  
    const handleCancel = (e) => {
      setIsModalVisible(false);
      e.stopPropagation()
    };
  
    // let ki_type = props.type

    // if (!ki_type || ki_type.length == 0) return ''

    // let ki_item = kilib.find(x=>x.name == ki_type) || kilib[0]

    const onSaveScreen_body =() => {

        let doc = document.querySelector("html")
        // let doc = document.getElementById("WebCamAvatar")

        console.log(doc)

        html2canvas(doc).then(canvas => {
            // document.body.appendChild(canvas)
    
            /*
            // скачать
            // var download = function(){
                var link = document.createElement('a');
                link.download = 'result.png';
                // link.href = document.getElementById('canvas').toDataURL()
                link.href = canvas.toDataURL()
                link.click();
            //   }
            */
           
    
            canvas.toBlob((file_blob)=>{
                PlaySnapshotEffect().then(()=> {
                    onAfterShot(file_blob)
                    setIsModalVisible(true);
                    SendScreenshotOnServer(file_blob).then(url => {
                        axios.get('https://sberai.vispstudio.ru/storage/api.php?b64='+b64+'&screen=' + url)
                        set_file_url(url);
                    })
                })
            })
    
        });
    }

    const onSaveScreen =() => {
        onBeforeShot()

        let iid = setTimeout(()=> {


            onSaveScreen_body()
            
        }, 10)
    }




    let contentRender = () => (

        <Card className={'ant-modal-content'} outlined={true} style={{height:'520px', background:'#fff'}}>
            <CardBody>
                <CardMedia
                    // src={coverImgUrl}
                    // placeholder={coverImgUrl}
                    // ratio={'1 / 1'}
                    // style={{height:'400px', background:'#fff'}}
                    />
                <CardContent cover={false}>
                    {/* { props.ki ? ( */}
                        {/* // background: '#000000a0' */}

                    {/* ) : '' } */}
                        <div style={{background:'#fff', textAlign: 'center', color:'#000'}}>
                            { file_url ? 
                            <><QRCode size={250} value={file_url}/>
                            <br/><img style={{maxWidth:250, maxHeight:140, marginTop:10, borderRadius:20}} src={file_url} />
                            <p>Наведи камеру телефона, <br/>чтобы открыть результат у себя.</p>
                            </> : <><LoadingOutlined style={{fontSize: 50, marginTop: 200}} /></> }


                        </div>
                    {/* <TextBox> */}


                        {/* <TextBoxBigTitle>Когнитивное искажение</TextBoxBigTitle> */}
                        {/* <TextBoxBiggerTitle dangerouslySetInnerHTML={{ __html:ki_item.title }}></TextBoxBiggerTitle> */}
                        {/* <TextBoxSubTitle>{'item.meeting_points'}</TextBoxSubTitle> */}
                    {/* </TextBox> */}
                    {/* <CardParagraph1 style={{ marginTop: '0.75rem' }} lines={4}>
                    </CardParagraph1> */}
                    {/* <p style={{opacity:0.5}} dangerouslySetInnerHTML={{ __html: ki_item.short.trim().replace(/\n/g, '<br/>') }}></p> */}
                    {/* <p dangerouslySetInnerHTML={{ __html: ki_item.long.trim().replace(/\n/g, '<br/>') }}></p> */}


                    {/* <Modal 
                        title={'234'} 
                        visible={isMoreVisible} 
                        footer={[]} 
                        // modalRender={contentRender}
                        // onOk={handleOk} 
                        onCancel={hideMore} 
                        width={'75%'}
                        >
                        1223
                    </Modal> */}
                    {/* <br/> */}
                    {/* <Button size={'s'} onClick={showMore}>Подробнее</Button> */}
                </CardContent>
            </CardBody>
        </Card>
        
        
    )


    return (
      <span className="">
        {/* <Button className="" type="primary" onClick={showModal} size={'s'}>
            <ThunderboltOutlined style={{ fontSize:'20px' }} />
            
            QR MODEL

            
        </Button> */}
        {/* <Button view="clear" size="l" pin="circle-circle" contentLeft={<><CameraOutlined /> и скачать <QrcodeOutlined /></> }  */}

        {/* <Button view="primary" onClick={showModal}> */}
        <Button view="primary" pin={"circle-circle"} style={{    background: 'linear-gradient(270deg,#24e029,#00b9ac)'}} onClick={showModal}>
            <CameraOutlined style={{fontSize:30}} /> &nbsp; и &nbsp; <QrcodeOutlined style={{fontSize:30}} />
        </Button>
        {/* <Button view="primary" size="l" 
				// style={{fontSize:'24px', position:'absolute', right:'60px', top:'5px'}} 
                // style={props.style}
				onClick={showModal} /> */}
        <Modal 
            title={'title'} 
            visible={isModalVisible} 
            // footer={[]} 
            modalRender={() => contentRender()}
            // modalRender={(n) => <><Button size={'s'} onClick={showMore}>Подробнее</Button>{n}</>}
            onOk={handleOk} 
            onCancel={handleCancel} 
            // width={'50%'}
            width={450}
            >
                
        </Modal>

        <style>
                {/* // display: none; */}
                {`
                body {
                    opacity: 1;
                    transition: opacity;
                }
                .white-flash-snapshot body {
                    opacity: 0;
                    transition: opacity;
                }
                .white-flash-snapshot {
                    background: white !important;
                }
                `}
                 {/* .white-flash-snapshot-2 {
                    opacity: 1;
                    transition: opacity;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: white;
                } */}
            </style>
      </span>
    );
  };

