
import React from 'react';

import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    // // Link,
    // useParams,
    // Redirect,
    useHistory
  } from "react-router-dom";

import StModel from './st_model' 

import axios from 'axios'

import { 
	// LoadingOutlined, DownloadOutlined, CameraOutlined, 
    // QrcodeOutlined 
    CameraFilled, 
} from '@ant-design/icons';

import  {
	Button,
} from '@sberdevices/plasma-ui';


// import { utils.img_blob_to_url, download } from './utils'
import utils from './utils'
import { useState } from "react";



function LogoHome() {
	let history = useHistory();
	return (
		<img src="sbersight_demo_logo.svg" 
				onClick={() => history.push('/') }
				style={{verticalAlign: 'middle', width: '90px', margin: '15px'}}/> 
	)
}


export default class PageWrapper extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
          style_list: [],
      }
    }

    componentDidMount() {
        axios.get('epoch_style_list.json').then(res => {
            console.log(res.data)
            this.setState({ style_list: res.data })
        })
            
    }

    render() {
        return (<>
            {/* {this.state.style_list.toString()} */}
            <Page category_style_list={this.state.style_list} />
        </>)
    }
}



export class LastPhoto extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            now: new Date(),
        }
    }
    componentDidMount() {
        this._iid = setInterval(() => this.update_now(), 10000);
        this.update_now();
    }
    componentWillUnmount() {
        clearInterval(this._iid);
    }
    update_now() {
        this.setState({ now: new Date() })
    }
    render() {
        let now = this.state.now;


        // return (<div style={{     flexGrow: 1, minHeight:'300px', background: " url(cover_gagarin.jpg) center bottom no-repeat", backgroundSize:'contain'}}>
        return (<div className="superimg" style={{     flexGrow: 1, minHeight:'300px', width:'100%', background: " url(http://45.89.225.221:9101/get_file?ref=result_img_without_logo.jpg&n="+Math.random() + ") center bottom no-repeat", backgroundSize:'contain'}}>
            {/* <img className="big_demo_img" src={"http://45.89.225.221:9101/get_file?ref=result_img_without_logo.jpg"} /> */}
        </div>)

        // return (
        //     <img className="big_demo_img" src={"http://45.89.225.221:9101/get_file?ref=result_img_without_logo.jpg&n="+Math.random()} />
        // )
        // return ( 
        //         <div style={{ flexGrow: 1 }}>
        //             <img className="big_demo_img" src={"http://45.89.225.221:9101/get_file?ref=result_img_without_logo.jpg"} />
        //         </div>
        //     )
    }
}


function Page({category_style_list}) {

    // console.log('category_style_list',  category_style_list)

    // const [ category_style_list, set_category_style_list ] = useState([]);

    // let category_style_list = [
    //     { title: 'Пользовательские', items: [], hide:true, custom_user_loaded: true },

    //     { title: 'До революции', items: [] },
    //     { title: '1917-1950', items: [] },
    //     { title: '1951-1979', items: [] },
    //     { title: '1980-1990', items: [] },
    //     { title: '1991-2000', items: [] },
    //     { title: '2001-2010', items: [] },
    //     { title: '2010+', items: [] },

    //     // , 1917-1950, 1951-1979, 1980-1990,1991-2000,2001-2010,2010+
    // ]

    // for ( let i = 1; i <= 32; i++) {
    //     let cat_index = Math.floor( Math.random() * (category_style_list.length - 1) ) + 1
    // //   category_style_list.push({
    //     category_style_list[ cat_index ].items.push({
    //         id: 'style'+i,
    //         filename: 'styles/mini/' + 'style'+i+'.jpg',
    //         userstyle: false,
    //         // category: get_random_category_id(categories)
    //         category_id: cat_index,
    //     })
    // }




    
    return (
        <div>
            <div style={{textAlign:'center'}}>
				<LogoHome/> 
			</div>   

            {/* http://45.89.225.221:9101/get_file?ref=result_img_logo.jpg */}

            

            <div className="big_demo_img_wrapper" style={{textAlign:'center'}}>

                <LastPhoto />

                <br/>

                <h1 style={{marginBottom:0}}>Эпохи в лицах</h1>
                <p>Искусственный интеллект впишет
                    <br /> ваш портрет в одно из лиц эпохи.</p>
            </div>

            <img className="sber_180" src="/sber_180_en.png"
				style={{ position:'absolute', right:'22px', top:'18px', width:'200px'}} />

            <img className="" src="/qr_last_photo.gif"
				style={{ position:'absolute', left:'30px', bottom:'30px', width:'20vw'}} />  

            {/* <div className="big_demo"></div> */}
            {/* <img className="big_demo_img" src={'cover_gagarin.jpg'} /> */}

                {/* // background: url(cover_gagarin.jpg);
                // width: 100vw;
                // height 100vh;
                // width: 100%;
                // max-height: 70vh; */}
            <style>
                { `
                html, body { min-height: 100vh; } 

                .big_demo_img {
                }

                .superimg {
    background-size: contain !important;
                }

                .big_demo_img_wrapper {
                    margin-top:50px;
                    display: flex;
                    flex-direction: column;
                    height: 75vh;
                    justify-content: center;
                    align-items: center;
                }

                div.badgeDone {
                    display: inline;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    line-height: 57px;
                    text-align: center;
                    /* margin: 53px; */
                    margin: 62px 5px;
                    padding: 0;
                }
                
                .footer {
                    text-align: center;
                    position: fixed;
                    z-index: 10;
                    bottom: 0;
                    left: 0;
                    right: 0;
                  }
                  
                ` }
            </style>


            <div className="footer">
                <small>powered by</small>
                <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
            </div>

        </div>
    )
}