// import logo from './logo.svg';
// import './App.css';

import React from 'react';

import {
  // BrowserRouter as Router,
//   HashRouter as Router,
//   Switch,
//   Route,
//   Link,
//   useParams,
  useHistory,
//   Redirect
} from "react-router-dom";


import {
    Container, //Row, Col, 
    // Cell, CellIcon,
    Underline,
    Header,
    Card,
    CardBody,
    CardContent,
    CardMedia,
    // CardHeadline1,
    CardParagraph1,
    TextBoxBigTitle,
    TextBoxBiggerTitle,
    TextBoxSubTitle,
    CarouselGridWrapper,
    Carousel,
    // Row,
    // CarouselCol,
    // ProductCard,
    TextBox,
    Button,
    Tabs, TabItem,

    // CarouselSection,
    CarouselCol,
    // MusicCard,

    

    Badge,
    useRemoteHandlers,
    // useToast,
    // TextField,
    // ActionButton,
    // Checkbox,
    // Toast,
    // ToastProvider,
 } from '@sberdevices/plasma-ui';

 import { Row as Row2, Col as Col2 } from 'antd';

 import { IRow, ICol } from './irow'


// import MeetingPage from './MeetingsPage';
// import MeetingList from './MeetingList'; 

// import StCards, {ScrImg} from './st/st_cards'; 
// import StyleTransferPage from './st/StyleTransferPage'; 
// import PeterHandPage from './st/PeterHandPage'; 
// import PeterFacePage from './st/PeterFacePage'; 

// import api from './api/api.js'


// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import './antd.dark.css'
// import { notification, Button } from 'antd';
// import { 
//     WarningOutlined, PlusSquareOutlined, FireOutlined, LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled 
// } from '@ant-design/icons';

// import  {
//       Button, Container,

// } from '@sberdevices/ui';




function toggleFullScreen() {
  if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}


function TopicCard({title, desc, url, img, tags}) {

    // let item = props.item
    let history = useHistory();
    
    // let handleClick = (id) => {
        //     history.push("/meetings/" + id)
        // }

    // let status = cardStatus(item)
    return (
        <Card
            // style={{ background: '#00000070' }}
            // style={{ background: '#ffff' }}
            // style={{ background: '#011a17', color: '#000 !important' }}
            tabIndex={1}
            outlined={true}
            scaleOnFocus={true}
            scaleOnHover={true}
            onClick={()=>history.push(url)}
        >
            <CardBody>
                <CardMedia
                    // src={img}
                    placeholder={img}
                    ratio={'4 / 3'}
                    />
                <CardContent cover={false}>
                    {/* <CardParagraph1 lines={4}>
                        {title}
                    </CardParagraph1> */}
                    {/* <TextBox> */}
                        {/* <TextBoxBigTitle>{title}</TextBoxBigTitle> */}
                        {/* <TextBoxBiggerTitle>{title}</TextBoxBiggerTitle> */}
                        <h2 style={{margin:0}}>{title}</h2>
                        {/* <TextBoxSubTitle>{item.meeting_points.join('; ')}</TextBoxSubTitle> */}
                    {/* </TextBox> */}
                    <CardParagraph1 style={{ margin: '0.5rem 0', fontSize:'0.9rem', lineHeight: '130%' }} lines={4}>
                        {desc}
                        {/* <Badge text={title} view={'clear'} style={{paddingLeft: 0, marginLeft:'-0.25rem'}}></Badge> */}
                        
                    </CardParagraph1>
                    {/* <CardParagraph1 lines={4}> */}
                    <div>
                        {/* {item.meeting_agreed_datetime} */}
                        { tags.map((x,i)=> <Badge key={i} text={ x } view="primary" style={{display:'inline', marginRight:'5px', background: '#30717c'}}></Badge> ) } 
                        {/* <Badge text={ status.active_mini  } view={'clear'}  style={{display:'inline'}}></Badge>  */}
                        {/* <br/> */}
                        {/* <br/> */}
                        {/* <Badge text={ status.end.calendar() } view={'clear'}  style={{display:'inline'}}></Badge>  */}
                    {/* </CardParagraph1> */}
                    </div>

                </CardContent>
            </CardBody>
        </Card>
    )
}

function LogoHome() {
	let history = useHistory();
	return (
		<img src="sbersight_demo_logo.svg" 
				onClick={() => history.push('/') }
				style={{verticalAlign: 'middle', width: '90px', margin: '15px'}}/> 
	)
}


export default function StartPage() {
  let history = useHistory();
  return (
    <Container>

        {/* <img className="sber_180" src="/sber_180.png" /> */}

        {/* <div style={{textAlign:'center'}}>
          <LogoHome/> 
        </div>    */}

        
        <Row2 align="middle">
          <Col2 span={8}>
            {/* <h3>Демо стенд</h3> */}
            <Button view={"clear"} tabIndex={1} size="s" onClick={() => { history.push('/epoch')}}>
              &nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          </Col2>
          <Col2 span={8}><div style={{textAlign:'center'}}><LogoHome/></div></Col2>
          <Col2 span={8} style={{paddingTop:'15px'}}>
              <IRow h="right" v="center">
                <ICol>
                  <Button view={"clear"} tabIndex={1} size="s" onClick={() => {toggleFullScreen()}}>
                    Полный экран
                  </Button>
                </ICol>
                <ICol>&nbsp;&nbsp;&nbsp;&nbsp;</ICol>
                <ICol>
                  <img className="sber_180" src="/sber_180_en.png" />
                </ICol>
              </IRow>    
          </Col2>
        </Row2>


        {/* <IRow h="full" v="center">
          <ICol><h3>Демо стенд 2</h3></ICol>
          <ICol>
            <div style={{textAlign:'center'}}>
              <LogoHome/> 
            </div>   
          </ICol>
          <IRow h="right" v="center">
            <ICol>
              <Button view={"clear"} tabIndex={1} size="s" onClick={() => {toggleFullScreen()}}>
                Полный экран
              </Button>
            </ICol>
            <ICol>&nbsp;&nbsp;&nbsp;&nbsp;</ICol>
            <ICol>
              <img className="sber_180" src="/sber_180.png" />
            </ICol>
          </IRow>
        </IRow> */}


        
        <br/>


        <Row2 gutter={[20, 20]}>

        {/* <Col2 xs={24} sm={12} md={8} lg={6} xl={6}>
            <TopicCard url="/epoch"
                title="Эпохи в лицах" 
                desc="Faceswap на портреты и фотографии культовых личностей эпохи." 
                tags={['FaceSwap', 'Deepfake']}
                img="/cover_gagarin.jpg"></TopicCard>
          </Col2> */}


          <Col2 xs={24} sm={12} md={8} lg={6} xl={6}>
            <TopicCard url="/style-transfer"
                title="Style Transfer" 
                // desc="Neural Style Transfer" 
                desc="Искусственный интеллект нарисует ваше фото в одном из стилей." 
                tags={['StyleTransfer']}
                img="/cover_st.jpg"></TopicCard>
          </Col2>


          <Col2 xs={24} sm={12} md={8} lg={6} xl={6}>
            <TopicCard url="/digital-peter-faceswap"
                title="Лик Петра I" 
                desc="Faceswap на портреты и&nbsp;статуи Петра&nbsp;I." 
                tags={['FaceSwap', 'Deepfake']}
                img="/cover_petr_faceswap.jpg"></TopicCard>
          </Col2>


          <Col2 xs={24} sm={12} md={8} lg={6} xl={6} >
            <TopicCard url="/digital-peter-handwrite"
                title="Почерк Петра&nbsp;I" 
                desc="Модель, которая преобразовывает печатный текст в&nbsp;текст, написанный рукой Петра&nbsp;I." 
                tags={['R-CNN', 'Text-to-Image']}
                img="/cover_petr_handwrite3.jpg"></TopicCard>
          </Col2>



          {/* <Col2 xs={24} sm={24} md={12} lg={8} xl={6}>
            <TopicCard url="/test"
                title="Test" 
                desc="Neural Style Transfer" 
                tags={['StyleTransfer']}
                img="/valli_main_bg_sketch.jpg"></TopicCard>
          </Col2>
          <Col2 xs={24} sm={24} md={12} lg={8} xl={6}>
            <TopicCard url="/test2"
                title="TestPetr" 
                desc="Neural Style Transfer" 
                tags={['StyleTransfer']}
                img="/valli_main_bg_sketch.jpg"></TopicCard>
          </Col2> */}
        </Row2>


        {/* <div style={{width:'400px', margin: '20px auto', display: 'flex', flexDirection: 'column'}}> */}
        <div style={{ maxWidth:'400px', margin: '20px auto', display: 'flex', flexDirection: 'column'}}>
{/* 
          <br />
          <br />
          
          
          <br />
          <Button onClick={() => history.push('/digital-peter-handwrite')} style={{display:'flex', flexDirection: 'column', height: '7rem'}}>
            <span style={{fontWeight:'bold', fontSize: '2rem', lineHeight: 1}}>Почерк Петра&nbsp;I</span>
            <small>Модель, которая преобразовывает печатный текст в&nbsp;текст, написанный рукой Петра&nbsp;I</small>
          </Button>
          <br />
          <Button onClick={() => history.push('/digital-peter-faceswap')} style={{display:'flex', flexDirection: 'column', height: '6rem'}}>
            <span style={{fontWeight:'bold', fontSize: '2rem', lineHeight: 1}}>Лик Петра I</span>
            <small>Faceswap на портреты и&nbsp;статуи Петра&nbsp;I</small>
          </Button>

          <br />
          <Button onClick={() => history.push('/style-transfer')} style={{display:'flex', flexDirection: 'column', height: '6rem'}}>
            <span style={{fontWeight:'bold', fontSize: '2rem', lineHeight: 1}}>Style Transfer</span>
            <small>Neural Style Transfer</small>
          </Button> */}

          {/* <br />
          <br />

          
          <br />
        <br /> */}

        {/* <Button view={"clear"} tabIndex={1} size="s" onClick={() => {toggleFullScreen()}}>
            Полный экран
        </Button>  */}

          <style>
              {`


                    .sber_180 { 
                      width:200px; 
                    }
                    /* position: absolute; top: 40px; right:40px; */

                    body { background-attachment: fixed; }

                    .footer {
                      text-align: center;
                      position: fixed;
                      z-index: 10;
                      bottom: 0;
                      left: 0;
                      right: 0;
                    }

              `}
          </style>

        </div>
        <div className="footer" style={{background:'none'}}>

            <small>powered by</small>
            <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 

        </div>

    </Container>
  )
}
