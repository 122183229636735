
import './irow.css'


export function IRow({ v, h, children }) {
    let rowClass = 'i-row'
    if (v) rowClass += ' v-' + v
    if (h) rowClass += ' h-' + h
    return (
        <div className={rowClass}>{children}</div>
    )
}

export function ICol({ v, h, children }) {
    let colClass = 'i-col'
    if (v) colClass += ' v-' + v
    if (h) colClass += ' h-' + h
    return (
        <div className={colClass}>{children}</div>
    )
}