
import { Button } from "@sberdevices/plasma-ui";


export default function SecondSlide({video, onNext=()=>{}}) {
    return (
        // <div style={{textAlign:'center'}}>
        <div>
            <style>{`
                body {  background: black }
            `}</style>

        
        <div style={{textAlign:'center', position:'relative', zIndex: 1}} onClick={()=>{document.getElementsByClassName('elena_video')[0].play()}}>

            {/* <h1>Описание теста</h1> */}

            {/* <h1 style={{marginTop:'25vh'}}>Инструкция</h1> */}
            {/* <img src={'/robot_with_btn.png'} width={350} height={301} style={{ marginTop:'5vh', marginBottom: -0}} /> */}

            <h1 style={{marginTop:'25vh', lineHeight: 1.2}}>Отлично! Ваши предпочтения <br/>уже проглядываются!</h1>

            {/* <p>Отлично! Ваш образ уже проглядывается. <br/>Осталось узнать детали!</p> */}

            {/* <h2 style={{marginTop:60}}>Вторая часть теста</h2> */}
            {/* <h2 style={{marginTop:30, fontWeight: 'normal'}}>Но нужно уточнить детали.</h2> */}
            <h2 style={{marginTop:30, lineHeight:1.2, fontWeight: 'normal'}}>Теперь Вам покажут несколько картинок подряд. 
            <br/>Захотите ли Вы работать с тем, что на них увидите?</h2>
            {/* <h2 style={{marginTop:60}}>Теперь Вам покажут несколько картинок подряд. Захотите ли Вы работать с тем, что на них увидите?</h2> */}
            {/* <h2 style={{margin:30, fontWeight: 'normal'}}>Приступим?</h2> */}
            <br/>
            {/* <h2 style={{}}>Вторая часть теста</h2> */}
            {/* <p> */}
            {/* Выводится текст примерно следующего содержания: */}
{/* Теперь нужно оценить нравится или нет образы,  */}
{/* <br/>которые искусственный интеллект подготовил специально для вас. */}
{/* <br/>
<br/> */}
{/* Все картинки созданы искусственным интеллектом.  */}
{/* <br/> */}

{/* <br/>Оценить картинку можно кнопкой, либо свайпом в её сторону. */}
{/* <br/> */}
{/* <br/> */}
{/* Тест начнётся через [ ]» */}
{/* В части «[ ]» выводится обратный отчёт. Отсчитываются 5 секунд. После этого происходит переход к этапу «Тесты» */}
            {/* </p> */}
            <Button view="primary" pin={"circle-circle"} style={{    background: 'linear-gradient(270deg,#24e029,#00b9ac)'}} onClick={onNext}>Продолжим!</Button>
            {/* <Button view="primary" pin={"circle-circle"} style={{    background: 'linear-gradient(270deg,#24e029,#00b9ac)'}}  onClick={onNext}>Начать с начала</Button> */}
        </div>
        <video className="elena_video" style={{position:'absolute', width:'100vw', height:'100vh', top: 0, left: 0, bottom:0, right:0, objectFit: 'cover'}} preload="auto" autoPlay loop playsInline> 
                {/* <source src="/who/video/01.mov" type="video/mov" /> */}
                {/* <source src="/who/video/0003.m4v" type="video/mp4" /> */}
                <source src={video} type="video/mp4" />
        </video>
        </div>
    )
}