// import logo from './logo.svg';
// import './App.css';

import React from 'react';

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   // Link,
//   useParams,
//   Redirect,
//   useHistory
// } from "react-router-dom";


// import MeetingPage from './MeetingsPage';
// import MeetingList from './MeetingList'; 

import StyleCards from './st_cards'; 


// import api from './api/api.js'



import { //WarningOutlined, PlusSquareOutlined, FireOutlined, 
	LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled, QrcodeOutlined } from '@ant-design/icons';
import  {
	Button,
} from '@sberdevices/plasma-ui';

// import axios from 'axios';

import QRmodal from './qrmodal'



// import { img_blob_to_url, download } from './utils'
import utils from './utils'

import './st_model.css'


export default class StyleTransferCtrl extends React.Component {
	constructor(props) {
		super(props)
		
		this.state = {
			active_style_id : '',
			active_style_obj : {},

			is_loading: false,
			is_painted: false,
			is_show_last_mode: false,

			painted_img_url: '',
			painted_img_url_with_logo: '',
		//   painted_img_blob: null,
			src_img_blob: null,
			src_img_url: '',

			custom_user_styles: [],
			// style_list: [ 
			// 	{ title: 'Пользовательские', items: [], hide:true, custom_user_loaded: true },
			// 	// ...(this.props.defStyles || [] )
			// 	...this.props.defStyles
			// ], // [{id, filename:url, userstyle:bool}]
		}
	}

  
	on_load_src_img = (input) => {
		if (!input.files || input.files.length == 0) return

		this.setState({
			src_img_url: utils.img_blob_to_url( input.files[0] ),
			src_img_blob: input.files[0], 
			is_painted: false, 
			active_style_id: '', 
			painted_img_url: '' 
		})
	}

	on_load_src_img_demo = (url_demo_img) => {
		if (!url_demo_img) return
		fetch(url_demo_img).then( t => t.blob().then( blob => {
			this.setState({
				src_img_url: url_demo_img,
				src_img_blob: blob, 
				is_painted: false, 
				active_style_id: '', 
				painted_img_url: '' 
			})
		} ))
	}

	on_load_userstyle_img = (input) => {
		if (!input.files || input.files.length == 0) return

		// let category_id_for_userstyle = 0;
		// let style_list = this.state.style_list

		// style_list[ category_id_for_userstyle ].items.push(
		// .push(
		// 	{
		// 		id: 'userstyle' + this.state.style_list.length, 
		// 		filename: utils.img_blob_to_url( input.files[0] ),  
		// 		blob: input.files[0], 
		// 		userstyle: true,
		// 		category_id: category_id_for_userstyle,
		// 	}
		// )
		this.setState({ custom_user_styles: [
			{
				id: 'userstyle' + this.state.custom_user_styles.length, 
				filename: utils.img_blob_to_url( input.files[0] ),  
				blob: input.files[0], 
				userstyle: true,
			}, 
			...this.state.custom_user_styles 
		] })
	}

	on_select_style = (style_info) => {
		console.log('on_select_style', style_info); 

		if (!this.state.src_img_blob) return;
		if (this.state.is_loading) return;
		
		this.setState({ 
            is_loading: true,
            active_style_id: style_info.id,
			active_style_obj: style_info
        })

        this.props.onMagic(style_info, this.state.src_img_blob).then(res => {
			console.log('onMagicRes', res)
			this.setState({
				is_painted: true, 
				is_loading: false, 
				is_show_last_mode: false,
				painted_img_url: res.painted_img_url,
				painted_img_url_with_logo: res.painted_img_url_with_logo,
				// painted_img_blob: res.painted_img_blob
			})
		}, err => {
			console.log('onMagicErr', err)
			this.setState({
				is_painted: false, 
				is_loading: false, 
				is_show_last_mode: false
			})
		})
	}

	open_dialog = (id_input) => {
		document.querySelector(id_input).click()
	}
	
	render() {
        let _ = this.state;
	    return ( <div className="st_model">

  
			<Button view="clear" size="l" pin="circle-circle" contentLeft={<CameraOutlined /> } 
				// style={{fontSize:'24px', position:'absolute', left:'5px', top:'5px', display: _.src_img_blob ? 'block' : 'none'}} 
				style={{fontSize:'24px', position:'absolute', left:'15px', top:'15px', display: _.src_img_blob ? 'block' : 'none'}} 
				onClick={() => this.open_dialog('#srcimg')} />


			<QRmodal value={_.painted_img_url_with_logo} 
                // style={{fontSize:'24px', position:'absolute', right:'60px', top:'5px', display: _.is_painted ? 'block' : 'none'}} />
                style={{fontSize:'24px', position:'absolute', right:'290px', top:'15px', display: _.is_painted ? 'block' : 'none'}} />
			<Button view="clear" size="l" pin="circle-circle" contentLeft={<DownloadOutlined /> } 
				// style={{fontSize:'24px', position:'absolute', right:'5px', top:'5px', display: _.is_painted ? 'block' : 'none'}} 
				style={{fontSize:'24px', position:'absolute', right:'235px', top:'15px', display: _.is_painted ? 'block' : 'none'}} 
				onClick={()=> fetch(_.painted_img_url_with_logo).then( t => t.blob().then( b => utils.download(b, this.props.filenameForDownload) )) } />

			<img className="sber_180" src="/sber_180_en.png"
				style={{ position:'absolute', right:'22px', top:'18px', width:'200px'}} />
		  
  
			<div id="model-loader" style={{ display: _.is_loading ? 'block' : 'none'}}>
				<LoadingOutlined />
			</div>
  
			<div style={{textAlign:'center', height: '75vh', display: 'flex', alignItems: 'center', flexDirection: 'column'  }}>
                <div style={{marginTop:'10vh', zIndex: 20, position: 'relative', display: !_.src_img_url ? 'block' : 'none'}}>
                    {this.props.startText(this)}
				</div>

				<input id="srcimg" style={{display:'none'}} type="file" accept=".jpg,.jpeg,.png" 
					onChange={(e) => this.on_load_src_img(e.target)}/>

				{ !_.is_painted ? 
					<>
						<img className="src-img" src={_.src_img_url} width="100%" 
							onClick={() => this.open_dialog('#srcimg')}
							style={{display: _.src_img_url ? 'block' : 'none'}}
							/>
					</>
					:
					<>
						<img className="painted-img" src={_.painted_img_url} width="100%"
							style={{display: this.props.compareMode && _.is_show_last_mode ? 'none' : 'block'}} 
							onClick={ () => this.setState({ is_show_last_mode: !_.is_show_last_mode })}
							/>
						<img className="src-img" src={_.src_img_url} width="100%"
							style={{display: this.props.compareMode && _.is_show_last_mode ? 'block' : 'none'}} 
							onClick={ () => this.setState({ is_show_last_mode: !_.is_show_last_mode })}
							/>
							{/* src={_.active_style_obj.filename} */}
					</>
				}

			</div>

  
			<div className="model-footer" style={{opacity: _.src_img_url == '' ? 0: 1, transition: 'opacity 1s'}}>

				{/* {_.style_list.toString()} || 
				{this.props.defStyles.toString()} */}

				<StyleCards customlist={_.custom_user_styles} list={this.props.defStyles} active={_.active_style_id} camera={this.props.userStyles}
					onCameraClick={e => this.open_dialog('#styleimg')}
					onSelect={e => this.on_select_style(e) }></StyleCards>
					
				<input id="styleimg" style={{display:'none'}} type="file" accept=".jpg,.jpeg,.png" 
					onChange={(e) => this.on_load_userstyle_img(e.target)}/>
				
			</div>
			  
		</div>)
	}
}



  
  