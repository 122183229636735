
import { Button } from "@sberdevices/plasma-ui";


export default function SecondSlide({video, onNext=()=>{}}) {
    return (
        <div>
            <style>{`
                body {  background: black }
            `}</style>

        
        <div style={{textAlign:'center', position:'relative', zIndex: 1}} onClick={()=>{document.getElementsByClassName('elena_video')[0].play()}}>
        {/* // <div style={{textAlign:'center'}}> */}
            {/* <h1>Описание теста</h1> */}

            <h1 style={{marginTop:'15vh'}}>План действий</h1>


            {/* <p> */}
            {/* Выводится текст примерно следующего содержания: */}
            {/* Чтобы пройти тест, Вам нужно будет несколько раз  */}
            {/* <br/>
            выбрать одну из предложенных пар картинок. 
            <br/>
            <br/>
            Все картинки созданы искусственным интеллектом. 
            <br/> */}

            {/* <br/>Чтобы выбрать картинку, просто нажмите на неё. */}
            <style>
                {`
                    ol {list-style: none;}
                    li { margin: 15px; margin-left:90px; }

                    ol li span { color:white; position: absolute; margin-left: -70px; margin-top:-5px; border: 2px solid white; width:40px; height:40px; text-align:center; border-radius:40px; line-height:40px; }

                `}
            </style>
            <p style={{fontSize:'1.5em', lineHeight:1.2}}>
            {/* Всё очень просто.  */}
            {/* <br/> */}
            Вам покажут ряд картин, нарисованных ИИ:
            </p>
            {/* <br/> */}
            <ol style={{textAlign:'left', width:500, margin:'40px auto', fontSize:'1.5em', lineHeight:1.2}}>
                <li><span>1</span>Выберите из предлагаемых вариантов, что Вам ближе; </li>
                <li><span>2</span>Уточните, с чем из нарисованного <br/>Вы бы хотели работать;</li>
                <li><span>3</span>Узнайте результат!</li>
            </ol>
            <p style={{fontSize:'1.5em', lineHeight:1.2}}>
            Хотите попробуем?
            </p>
            {/* Тест начнётся через [ ]» */}
            {/* В части «[ ]» выводится обратный отчёт. Отсчитываются 5 секунд. После этого происходит переход к этапу «Тесты» */}
            {/* </p> */}
            <Button view="primary" pin={"circle-circle"} style={{    background: 'linear-gradient(270deg,#24e029,#00b9ac)'}} onClick={onNext}>Да, приступим!</Button>
        </div>
        <video className="elena_video" style={{position:'absolute', width:'100vw', height:'100vh', top: 0, left: 0, bottom:0, right:0, objectFit: 'cover'}} preload="auto" autoPlay loop playsInline> 
                {/* <source src="/who/video/01.mov" type="video/mov" /> */}
                {/* <source src="/who/video/0002.m4v" type="video/mp4" /> */}
                <source src={video} type="video/mp4" />
        </video>
        </div>
    )
}