
import ModelOutputField from './ModelOutputField'


export default function ModelOutputForm({model, results={}, results_prepare=[]}) {
    // const [inputs_data, set_inputs_data] = useState({})
    return (
        <div style={{background:'#00000030', padding:30, borderRadius:20}}>
            <h3 style={{marginTop:0}}>Outputs</h3>
            {model?.output_fields?.map((x,i) => 
                // <ModelOutputField key={i} field={x} res={results[x.proxy_as]} />
                <ModelOutputField key={i} field={x} res={results[x.proxy_as]} res_prepare={results_prepare?.find(r => r.id == x.id)} />
            )}

            {/* <p>{JSON.stringify(results)}</p>  */}
        </div>
    )
}
