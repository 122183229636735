
import { Button } from "@sberdevices/plasma-ui";
import axios from "axios";
import { useState } from "react";


export default function FeedBack({b64='', onNext=()=>{}, ...rest}) {

    // const = useState
    
    const [active_status, set_active_status] = useState(0);

    const answers = [
        {id: 1, smile:'😡', text:<>совсем<br/>нет</>},
        {id: 2, smile:'😳', text:<>скорее<br/>нет</>},  // 😒 
        {id: 3, smile:'😐', text:<>не<br/>понял</>},
        {id: 4, smile:'😄', text:<>скорее<br/>да</>},
        {id: 5, smile:'😍', text:<>точно<br/>да</>},
    ]

    const normal_style = {
        width:'100%',
        padding:'15px 5px', 
        border: '0px solid #ffffff10', 
        textAlign:'center',
    }
    const style_active = {
        background: '#ffffff30',
        borderRadius: 10,
    }
    
    
    return (
        <div {...rest}>
            <h3 style={{margin:0, marginBottom:10}}>Прав ли оказался ИИ?</h3>

            <div style={{display:'flex', width:'100%', lineHeight: 1.2 }}>
                { answers.map((x,i) => (
                    // <div key={i} className={ active_status == x.id ? 'active' : '' }
                    <div key={i}
                        onClick={()=>{
                            set_active_status(x.id)
                            axios.get('https://sberai.vispstudio.ru/storage/api.php?b64='+b64+'&screen=&smile=' + x.id);
                        }}
                        style={{...normal_style, ...( active_status == x.id ? style_active : {} ) }}
                    >
                        {/* <span style={{fontSize:30}}>{x.smile}</span> */}
                        <img src={'/who/smiles/' + x.id + '.png'} width={'50%'} />
                        <br/>
                        {x.text}
                    </div>
                ))}
                {/* <div style={{width:'100%',padding:5, border: '0px solid #ffffff10', textAlign:'center'}}><span style={{fontSize:30}}>😡</span><br/>совсем нет</div>
                <div style={{width:'100%',padding:5, border: '0px solid #ffffff10', textAlign:'center'}}><span style={{fontSize:30}}>😳</span><br/>скорее нет</div> 
                <div style={{width:'100%',padding:5, border: '0px solid #ffffff10', textAlign:'center'}}><span style={{fontSize:30}}>😐</span><br/>не понял</div>
                <div style={{width:'100%',padding:5, border: '0px solid #ffffff10', textAlign:'center'}}><span style={{fontSize:30}}>😄</span><br/>скорее да</div>
                <div style={{width:'100%',padding:5, border: '0px solid #ffffff10', textAlign:'center'}}><span style={{fontSize:30}}>😍</span><br/>точно да</div> */}
            </div>

        </div>
    )
}
// export default function StartSlide({onNext=()=>{}}) {
//     return (
//         <div style={{textAlign:'center'}}>

//             <img src={'/front_robot.png'} width={300} height={370} style={{ marginTop:'5vh', marginBottom: -140}} />
//             {/* <p style={{marginTop:'3vh', textShadow:'rgb(0 0 0) 0px 0px 9px, rgb(0 0 0) 0px 0px 23px'}}>Интерактивный тест</p> */}
//             <p style={{marginTop:'3vh', textShadow:'rgb(0 0 0) 0px 0px 9px, rgb(0 0 0) 0px 0px 23px'}}>Интерактивный тест</p>
//             <h1 style={{fontSize:60, textShadow:'rgb(0 0 0) 0px 0px 9px, rgb(0 0 0) 0px 0px 23px'}}>Кто ты в науке?</h1>
//             <h2 style={{fontWeight:"normal", lineHeight:1.2}}>Хотите узнать, какое направление науки Вам интересно? <br/>Искусственный интеллект Вам поможет!</h2>
//             {/* <p>Отображается название стенда и «кнопка», «нажав» на которую пользователь может начать проходить тест.
// Нажатие «кнопки «Начать» ведёт к переходу к этапу «Описание теста»</p> */}
//             <br/>
//             <Button view="primary" pin={"circle-circle"} style={{    background: 'linear-gradient(270deg,#24e029,#00b9ac)'}} onClick={onNext}>Да, конечно!</Button>
//         </div>
//     )
// }
