

import { Button } from "@sberdevices/plasma-ui";
import { Row, Col } from "antd";
import axios from "axios";
import html2canvas from "html2canvas";
import { useState, useEffect } from "react";
import { useParams } from "react-router";
import Debug from "../../../elem/Debug";
import QRmodal from "../../qrmodal";
import QRmodalSnapshot from "../../qrmodalSnapshot";
import WebCamAvatar from "../WebCamAvatar";
import FeedBack from "./FeedBack";




function topRating(rating) {
    // let rating = answersRating(answers, current_part.part_config)
    let top = Object.keys(rating).sort((a,b) => rating[b] - rating[a] )[0]

    console.log( rating, top )
    return top;
}


let jobs = {

    // start -> nature, tech, human, sign, art

    // Человек – знаковая система: Физика и математика/Экономист
    // Человек – техника: машиностроение/космонавтика 
    // Человек – человек: история/психология
    // Человек – природа: Биология и химия/География и геология
    // Человек – художественный образ: искусствоведение/архитектура

    "human_sign": {
        // title: 'Человек-цифра',
        title: 'Цифрой',
        img: '',
        desc: <>
            Судя по выбранным картинкам, вы предпочитаете 
            взаимодействовать с&nbsp;<b>точными науками</b>.
        </>
    },
    "human_nature": {
        // title: 'Человек-природа',
        title: 'Природой',
        img: '',
        desc: <>
            Судя по выбранным картинкам, вы предпочитаете 
            взаимодействовать с&nbsp;<b>естественными науками</b>.
        </>
    },
    "human_tech": {
        // title: 'Человек-техника',
        title: 'Техникой',
        img: '',
        desc: <>
            Судя по выбранным картинкам, вы предпочитаете 
            взаимодействовать с&nbsp;<b>техникой</b>.
        </>
    },
    "human_human": {
        // title: 'Человек-человек',
        title: 'Человеком',
        img: '',
        desc: <>
            Судя по выбранным картинкам, вы предпочитаете 
            взаимодействовать с&nbsp;<b>людьми</b>.
        </>
    },

    ////////////////////
    // start -> nature, tech, human, sign, art

    // nature -> bio, geo
    // sign -> math, econom
    // tech -> macihne, space
    // human -> histoty, psy
    // art -> artist, architectute

    // human_sign
    // "type": "Mashinnoe_obuchenie", type": "Машинное обучение",
    // "type": "Nauka_o_dannyh", type": "Наука о данных",
    // "type": "Kriptografiya_i_kompyuternaya_bezopasnost", type": "Криптография и компьютерная безопасность",
    // "type": "Ekonomika", type": "Экономика",
    
    // human_natur
    // "type": "Gennaya_terapiya", type": "Генная терапия",
    // "type": "Sredstva_napravlennoj_dostavki_lekarstv", type": "Средства направленной доставки лекарств",
    // "type": "Tkanevaya_i_kletochnaya_inzheneriya", type": "Тканевая и клеточная инженерия",
    // "type": "Nejrohirurgiya", type": "Нейрохирургия",
    
    // human_tech
    // "type": "Kosmonavtika", type": "Космонавтика",
    // "type": "Materialovedenie", type": "Материаловедение",
    // "type": "Energetika", type": "Энергетика",
    // "type": "Robototekhnika", type": "Робототехника",
    
    // human_human
    // "type": "YUrisprudenciya", type": "Юриспруденция",
    // "type": "Sociologiya", type": "Социология",
    // "type": "Antropologiya", type": "Антропология",
    // "type": "Psihologiya", type": "Психология",

    // human_sign
    "Mashinnoe_obuchenie": { title: "Машинное обучение", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>точными науками</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Машинное обучение</b>.</>, img:"/who/human_logo/Mashinnoe_obuchenie.png"},
    "Nauka_o_dannyh": { title: "Наука о данных", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>точными науками</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Наука о данных</b>.</>, img:"/who/human_logo/Nauka_o_dannyh.png"},
    "Kriptografiya_i_kompyuternaya_bezopasnost": { title: "Криптография и комп.безопасность", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>точными науками</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Криптография и комп.безопасность</b>.</>, img:"/who/human_logo/Kriptografiya_i_kompyuternaya_bezopasnost.png"},
    "Ekonomika": { title: "Экономика", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>точными науками</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Экономика</b>.</>, img:"/who/human_logo/Ekonomika.png"},
    
    // human_natur
    "Gennaya_terapiya": { title: "Генная терапия", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>естественными науками</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Генная терапия</b>.</>, img:"/who/human_logo/Gennaya_terapiya.png"},
    "Sredstva_napravlennoj_dostavki_lekarstv": { title: "Средства доставки лекарств", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>естественными науками</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Средства доставки лекарств</b>.</>, img:"/who/human_logo/Sredstva_napravlennoj_dostavki_lekarstv.png"},
    "Tkanevaya_i_kletochnaya_inzheneriya": { title: "Тканевая и клеточная инженерия", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>естественными науками</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Тканевая и клеточная инженерия</b>.</>, img:"/who/human_logo/Tkanevaya_i_kletochnaya_inzheneriya.png"},
    "Nejrohirurgiya": { title: "Нейрохирургия", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>естественными науками</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Нейрохирургия</b>.</>, img:"/who/human_logo/Nejrohirurgiya.png"},
    
    // human_tech
    "Kosmonavtika": { title: "Космонавтика", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>техникой</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Космонавтика</b>.</>, img:"/who/human_logo/Kosmonavtika.png"},
    "Materialovedenie": { title: "Материаловедение", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>техникой</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Материаловедение</b>.</>, img:"/who/human_logo/Materialovedenie.png"},
    "Energetika": { title: "Энергетика", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>техникой</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Энергетика</b>.</>, img:"/who/human_logo/Energetika.png"},
    "Robototekhnika": { title: "Робототехника", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>техникой</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>Робототехника</b>.</>, img:"/who/human_logo/Robototekhnika.png"},
    
    // human_human
    "YUrisprudenciya": { title: "Юриспруденция", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>людьми</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>"Юриспруденция"</b>.</>, img:"/who/human_logo/YUrisprudenciya.png"},
    "Sociologiya": { title: "Социология", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>людьми</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>"Социология"</b>.</>, img:"/who/human_logo/Sociologiya.png"},
    "Antropologiya": { title: "История",  // Антропология
        desc:<>Из научных направлений, связанных с&nbsp;<b>людьми</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>"Антропология"</b>.</>, img:"/who/human_logo/Antropologiya.png"},
    "Psihologiya": { title: "Психология", 
        desc:<>Из научных направлений, связанных с&nbsp;<b>людьми</b>, вы выбрали больше всего картинок, связанных с&nbsp;<b>"Психология"</b>.</>, img:"/who/human_logo/Psihologiya.png"},





    "bio": {
        title: 'Химик-биолог',
        img: '/who/sticker_biolog.webp',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>естественными науками</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>химией и&nbsp;биологией</b>.
        </>
    },
    "geo": {
        title: 'Географ-геолог',
        img: '/who/sticker_geo.webp',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>естественными науками</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>геологией и географией</b>.
        </>
    },

    "math": {
        title: 'Физик-математик',
        img: '/who/sticker_mathematic.webp',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>точными науками</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>физикой и&nbsp;математикой</b>.
        </>
    },
    "econom": {
        title: 'Экономист',
        img: '/who/sticker_econom.webp',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>точными науками</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>экономикой</b>.
        </>
    },

    "macihne": {
        title: 'Инженер',
        // title: 'Машиностроение',
        img: '/who/sticker_engineer.webp',
        // img: '/who/sticker_tech.webp',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>техникой</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>машиностроением</b>.
        </>
    },
    "space": {
        title: 'Космонавт',
        img: '/who/sticker_space2.webp',
        // img: '/who/sticker_space.png',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>техникой</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>космосом</b>.
        </>
    },

    "histoty": {
        title: 'Историк',
        img: '/who/sticker_hist.webp',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>людьми</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>историей</b>.
        </>
    },
    "psy": {
        title: 'Психолог',
        img: '/who/sticker_psy.webp',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>людьми</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>психологией</b>.
        </>
    },

    // "biologist": {
    //     title: 'Биолог',
    //     img: '/who/sticker_biolog.webp',
    //     desc: <>
    //         Из научных направлений, связанных с&nbsp;<b>естественными науками</b>, 
    //         вы выбрали больше всего картинок, связанных с&nbsp;<b>природой</b>.
    //     </>
    // },

    "artist": {
        title: 'Искусствовед',
        img: '/who/sticker_art.webp',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>творчеством</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>искусством</b>.
        </>
    },
    "architectute": {
        title: 'Архитектор',
        img: '/who/sticker_arch.webp',
        desc: <>
            Из научных направлений, связанных с&nbsp;<b>творчеством</b>, 
            вы выбрали больше всего картинок, связанных с&nbsp;<b>архитектурой</b>.
        </>
    }
}

const jobs_term_select = (key) => {
    if (jobs[key]) {
        return jobs[key] 
    } else {
        return {
            title: 'Некто',
            img: '/who/sticker_medic.webp',
            desc: <>
                bla bla bla
            </>
        }
    }
}

// sticker.webp
// sticker_medic.webp
// sticker_mathematic.webp
// sticker_engineer.webp
// sticker_biolog.webp

function Chart({data, max = 3}) {
    if (!data) return ''
    const val_max = Math.max( ...Object.values(data) )
    return (
        // <div style={{background:'#00000090', padding:15, borderRadius:10, lineHeight:1.5, marginBottom:10}}>
        <div style={{ lineHeight:1.5, marginBottom:10}}>
            {/* <Debug json={data} /> */}
            <table style={{width:'100%'}}><tbody>
            { Object.keys(data).sort((a,b) => data[b] - data[a]).slice(0, max).map((x,i) => (
                <tr key={i}>
                    <td style={{whiteSpace:'nowrap', paddingRight:15, width:'40%'}}>
                        {jobs_term_select(x).title}
                    </td>
                    <td style={{width:'60%'}}>
                        <div style={{
                            // background:'green',
                            background: 'linear-gradient(-90deg, #0bd818, #00B2EA)',
                            borderRadius: 5,
                            padding:'2px 7px',
                            lineHeight: 1,
                            width: (data[x] / val_max * 90 + 10)  +'%',
                            borderBottom: '5px solid #00000070',
                            borderTop: '5px solid #FFFFFF00',

                        }}>
                            {/* {data[x]} */}
                        </div>

{/* strokeColor={{ '100%': '#0bd818', '0%': '#00B2EA' }} */}

                    </td>
                </tr>
            )) }
            </tbody>
            </table>
            {/* { Object.keys(data).map(x => (
                <Row align="middle">
                    <Col span="12">{jobs_term_select(x).title}</Col>
                    <Col>
                        <div style={{
                            background:'green',
                            borderRadius: 5,
                            padding:5,
                            lineHeight: 1
                        }}>{data[x]}</div>
                    </Col>
                </Row>
            )) } */}
        </div>
    )
}

// function ChartPanel({title = 'Tест Климова', term, data}) {
//     const top = topRating( data )
//     return (
//         <Row gutter={[40]}>
//             <Col xs={0} sm={0} md={2} lg={5}></Col>
//             {/* <Col span={5}> */}
//             {/* <Col style={{width:'100%', maxWidth: 320}}> */}
//             <Col xs={24} sm={10} md={8} lg={6} >
//                 <h3 style={{margin:0}}>{title}</h3>
//                 <p style={{fontSize:13, lineHeight:1.2, opacity:0.7}}>{jobs_term_select(top).desc}</p>
//             </Col>
//             {/* <Col span={8}> */}
//             {/* <Col style={{width:'100%', maxWidth: 350}}> */}
//             <Col xs={24} sm={14} md={12} lg={8}>
//                 <Chart data={data} />
//             </Col>
//             <Col xs={0} sm={0} md={2} lg={5}></Col>
//         </Row>
//     )
// }




// function PlaySnapshotEffect() {
//     return new Promise((resolve, reject) => {
//         document.getElementsByTagName('html')[0].classList.add("white-flash-snapshot");
//         setTimeout(()=> {
//             document.getElementsByTagName('html')[0].classList.remove("white-flash-snapshot");
//             resolve()
//         }, 100)
//     })
// }

export default function ResultSlide({onNext=()=>{}}) {
    const {res} = useParams();

    const result = JSON.parse( atob(res) )
    const firstTop = topRating( result[0] )
    let lastTop = topRating( result[result.length - 1] )

    const [is_stop_frame, set_is_stop_frame] = useState(false);


    useEffect(()=>{
        axios.get('https://sberai.vispstudio.ru/storage/api.php?b64='+res+'&screen=');
    },[])

    // const onShot = () => {
    //     PlaySnapshotEffect().then(() => {

    //     })
    // }   


    // nature -> bio, geo
    // sign -> math, econom
    // tech -> macihne, space
    // human -> histoty, psy
    // art -> artist, architectute
    // lastTop = 'artist';
    // lastTop = 'bio';
    // lastTop = 'geo';
    // lastTop = 'math';
    // lastTop = 'econom';
    // lastTop = 'macihne';
    // lastTop = 'space';
    // lastTop = 'histoty';
    // lastTop = 'psy';
    // lastTop = 'artist';
    // lastTop = 'architectute';

    return (
        <div>
            {/* <div style={{textAlign:'center'}}>
                <img width="256" src={jobs_term_select(lastTop).img} />
                <h3 style={{marginBottom:10}}>Результаты теста</h3>
                <h1 style={{lineHeight:1, marginTop: 0}}>Вы — {jobs_term_select(lastTop).title}</h1>
            </div>

            <ChartPanel title="Tест Климова" data={result[0]} />
            <ChartPanel title="Карта интересов" data={result[1]} /> */}

            <img src={'/yofs_logo.png'} style={{ position:'absolute', top: '3vh', right:'3vh', width:'17vh' }} />
            {/* <img src={'/kc_logo.png'} style={{ position:'absolute', top: '4vh', right:'24vh', width:'13vh' }} /> */}
            {/* <img src={'/kc_logo.png'} style={{ position:'absolute', top: '4vh', left:'4vh', width:'13vh' }} /> */}
            {/* <img src={'/kc_logo_h.png'} style={{ position:'absolute', top: '3vh', left:'3vh', width:'36vh' }} /> */}
            <img src={'/kc_logo.png'} style={{ position:'absolute', top: '3vh', left:'3vh', width:'22vh' }} />


            <Row gutter={[20]} align="middle">
                <Col xs={0} lg={10} flex="auto"></Col>
                <Col xs={24} lg={8} flex="auto"  style={{textAlign:'center', background:'#00000000', padding:35, borderRadius:20, lineHeight:1.5, marginBottom:10, boxShadow:'0 0 20px #ffffff30-'}}>
                    <div>
                        {/* <br/> */}

                        {/* <Button onClick={()=>{set_is_stop_frame(!is_stop_frame)}}>is_stop_frame = {is_stop_frame ? 1: 0}</Button> */}

                        <WebCamAvatar stopFrame={is_stop_frame} />
                        {/* <WebCamAvatar  /> */}
                        <img width="70" style={{width: "150px",
    zIndex:2,
    marginTop: '-110px',
    marginRight: '-270px',
    position: 'relative'}} src={jobs_term_select(lastTop).img} />
                        {/* <h3 style={{marginBottom:0}}>Результаты теста</h3> */}
                        <h3 style={{marginBottom:0}}>ИИ считает, что ваша склонность —</h3>
                        {/* <p style={{marginTop:0, fontSize:13, marginBottom: 0}}>ваш научный интерес</p> */}
                        <h1 style={{lineHeight:1, marginTop: 0, marginBottom:35}}>{jobs_term_select(lastTop).title}</h1>


                        {/* <br/> */}

                        {/* <QRmodal value={"http://sberai.vispstudio.ru/#/who/result/" + res} style={{fontSize:'34px' }} /> */}

                        <QRmodalSnapshot b64={res} onBeforeShot={()=>{
                            // return new Promise((resolve, reject) => {
                                set_is_stop_frame(true);
                            //     setTimeout(() => {
                            //         resolve();
                            //     }, 50)
                            // }) 
                        }} onAfterShot={()=>{set_is_stop_frame(false)}} style={{fontSize:'34px' }} />

                        {/* <br/>
                        <br/>
                        <br/>
                        <br/> */}

                        {/* <Button view="primary" pin={"circle-circle"} style={{    background: 'linear-gradient(270deg,#24e029,#00b9ac)'}}  onClick={onNext}>Начать с начала</Button> */}
                        
                    </div>    
                </Col>
                <Col xs={24} lg={10}  style={{ lineHeight:1.5, marginBottom:10, boxShadow:'0 0 20px #ffffff30-'}}>
                    <div style={{background:'#000000aa', padding:35, borderRadius:20,}}>
                        {/* <h2 style={{margin: '0px 0 10px'}}>Tест Климова</h2> */}
                        {/* <h2 style={{fontSize:20, lineHeight:1.2, margin: '0px 0 10px'}}>Судя по всему, Вы предпочитаете <br/>взаимодействовать с:</h2>
                        <Chart data={result[0]} /> */}
                        
                        {/* <h2 style={{margin: '40px 0 10px'}}>Карта интересов</h2> */}
                        {/* <h2 style={{fontSize:20, lineHeight:1.2, margin: '40px 0 10px'}}> */}
                        <h2 style={{fontSize:20, lineHeight:1.2, margin: '0px 0 10px'}}>
                            {/* И из научных направлений, видимо, <br/>Ваш научный интерес: */}
                            {/* Топ 4 Ваших научных склонностей <br/>по мнению ИИ: */}
                            Топ 3 Ваших научных склонностей <br/>по мнению ИИ:
                        </h2>
                        <Chart data={result[1]} />
                        <h2 style={{fontSize:16, lineHeight:1.2, margin: '40px 0 10px'}}>Помните: ИИ тоже может ошибаться, 
                        <br/>так что в работе стенда могут быть погрешности. 
                        <br/>Ваша жизнь зависит только от Вас!</h2>
                        <h2 style={{fontSize:18, lineHeight:1.2, margin: '10px 0 0px', color: "#0bc57e"}}>#ГодНауки</h2>
                    </div>
                    <FeedBack b64={res} style={{background:'#000000aa', padding:35, borderRadius:20, marginTop: 10}} />
                    <div style={{textAlign:'center'}}>
                        <Button view="secondary" style={{margin:'10px 0'}} pin={"circle-circle"}   onClick={onNext}>Начать с начала</Button>

                    </div>
                </Col>
                <Col flex="auto"></Col>
                {/* <Col flex="auto"></Col> */}
            </Row>
            
            {/* <br/> */}
            <div style={{textAlign:'center'}}>
                {/* <Button view="primary" pin={"circle-circle"} style={{    background: 'linear-gradient(270deg,#24e029,#00b9ac)'}}  onClick={onShot}>Shot</Button> */}
                {/* <Button view="primary" pin={"circle-circle"} style={{    background: 'linear-gradient(270deg,#24e029,#00b9ac)'}}  onClick={onSaveScreen}>Скачать результаты</Button> */}
                {/* &nbsp; */}
                {/* &nbsp; */}
                
            </div>
            {/* <br/> */}
            {/* <br/> */}

            {/* <div class="white-flash-snapshot-2"> */}

            {/* <img width="100" src="http://sberai.site:8110/proftest/1638479095/screen.jpg" /> */}
            {/* <img width="100" src="http://sberai.site:8110/proftest/1638479755/screen.png" /> */}

            {/* <QRmodal value={"http://sberai.vispstudio.ru/#/who/result/" + res} style={{fontSize:'24px', position:'absolute', top:25, right:25 }} /> */}


            
        </div>
    )
}



