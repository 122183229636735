import { Button } from "@sberdevices/plasma-ui"
import { useState } from "react"
import Debug from "../../../elem/Debug"
import ModelInputField from "./ModelInputField"


import { //WarningOutlined, PlusSquareOutlined, FireOutlined, 
	LoadingOutlined, 
    // DownloadOutlined, CameraOutlined, CameraFilled, QrcodeOutlined 
} from '@ant-design/icons';


export default function ModelInputForm({model, onExec=()=>{}, execLoading=false}) {
    const [inputs_data, set_inputs_data] = useState({})
    const [inputs_full_data, set_inputs_full_data] = useState({})
    return (
        <div style={{background:'#00000030', padding:30, borderRadius:20}}>
            {/* <CardContent> */}
                {/* {model?.primary_image_url && <img src={model.primary_image_url} width={100} /> } */}
                {/* <img src={model.secondary_image_url} width={100} /> */}
                {/* <CardHeadline3>{model.model_name}</CardHeadline3> */}
                {/* <CardBody>{model.description}</CardBody> */}
                {/* <p>{JSON.stringify(model.tags)}</p> */}

                <h3 style={{marginTop:0}}>Inputs</h3>
                {model?.input_fields?.map((x,i) => 
                    <ModelInputField key={i} field={x} 
                        value={inputs_data[x.proxy_as]} 
                        // onInput={v => set_inputs_data( { ...inputs_data, [x.proxy_as]: v } )}
                        onInput={v => {
                            // set_inputs_data( { ...inputs_data, [x.field_type]: v.value } )
                            // set_inputs_full_data ( { ...inputs_data, [x.field_type]: v } )
                            set_inputs_data( { ...inputs_data, [x.proxy_as]: v.value } )
                            set_inputs_full_data ( { ...inputs_data, [x.proxy_as]: v } )
                        }}
                        // onInput={v => set_inputs_data( { ...inputs_data, ['image']: v } )}
                        />
                )}

                {/* <p>{JSON.stringify(inputs_data)}</p> */}
                {/* <Debug json={inputs_data} /> */}
                {/* <Debug json={inputs_full_data} /> */}

                {/* <h3>Outputs</h3>
                {model?.output_fields?.map((x,i) => <ModelInputField key={i} field={x} />)} */}

                <br/>

                <Button 
                    // style={{background: 'linear-gradient(90.63deg, #565AEF -104.18%, #2529DC 108.96%)'}} 
                    stretch={true} disabled={execLoading} view={'primary'} size={'l'}
                    onClick={() => onExec(inputs_data, inputs_full_data)}
                >
                        {execLoading ? <LoadingOutlined/> : 'Запустить модель' }
                </Button>
                
            {/* </CardContent> */}
        </div>
    )
}
