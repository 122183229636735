

// The width and height of the captured photo. We will set the
  // width to the value defined here, but the height will be
  // calculated based on the aspect ratio of the input stream.

import { useEffect } from "react";

  var width = 320;    // We will scale the photo width to this
  var height = 0;     // This will be computed based on the input stream

  // |streaming| indicates whether or not we're currently streaming
  // video from the camera. Obviously, we start at false.

  var current_stream = null;
  var streaming = false;

  // The various HTML elements we need to configure or control. These
  // will be set by the startup() function.

  var video = null;
  var canvas = null;
  var context = null;
  var photo = null;
  var startbutton = null;
//   var iid = null;


  function onClickTakePicture (ev) {
    takepicture();
    ev.preventDefault();
  }

  function onCanPlay (ev) {
    if (!streaming) {
        height = video.videoHeight / (video.videoWidth/width);
      
        // Firefox currently has a bug where the height can't be read from
        // the video, so we will make assumptions if this happens.
      
        if (isNaN(height)) {
          height = width / (4/3);
        }
      
        video.setAttribute('width', width);
        video.setAttribute('height', height);
        canvas.setAttribute('width', width);
        canvas.setAttribute('height', height);
        streaming = true;
    }
  }

  function startup() {
    video = document.getElementById('WebCamAvatar-video');
    canvas = document.getElementById('WebCamAvatar-canvas');
    context = canvas.getContext('2d');

    // iid = setInterval(()=> {
    //     takepicture()
    // }, 1000)
    // photo = document.getElementById('WebCamAvatar-photo');
    // startbutton = document.getElementById('WebCamAvatar-startbutton');
    if (navigator && navigator.mediaDevices) {

        navigator.mediaDevices.getUserMedia({video: true, audio: false})
            .then(function(stream) {
                current_stream = stream;
                window.current_stream = current_stream;
                video.srcObject = stream;
                video.play();
            })
            .catch(function(err) {
                console.log("An error occurred: " + err);
            });
    
        video.addEventListener('canplay', onCanPlay, false);
        // startbutton.addEventListener('click', onClickTakePicture, false);
        
        clearphoto();
    }
  }

  function stop() {
    if (streaming) {
        streaming = false;
        current_stream.getVideoTracks()[0].stop()
        video.removeEventListener('canplay', onCanPlay);

        // clearInterval(iid)
        // startbutton.removeEventListener('click', onClickTakePicture);
    } 
  }

  // Fill the photo with an indication that none has been
  // captured.

  function clearphoto() {
    // var context = canvas.getContext('2d');
    context.fillStyle = "#AAA";
    context.fillRect(0, 0, canvas.width, canvas.height);

    var data = canvas.toDataURL('image/png');
    // photo.setAttribute('src', data);


  }
  
  // Capture a photo by fetching the current contents of the video
  // and drawing it into a canvas, then converting that to a PNG
  // format data URL. By drawing it on an offscreen canvas and then
  // drawing that to the screen, we can change its size and/or apply
  // other changes before drawing it.

  

  function takepicture() {
    if (width && height) {
        canvas.width = width;
        canvas.height = height;
        // console.log(1)
        context.drawImage(video, 0, 0, width, height);
        // console.log(2)
        
        // var data = canvas.toDataURL('image/png');
        // console.log(3)
        
        //   photo.setAttribute('src', data);
        //   photo.setAttribute('src', data);
        // let me = document.getElementById('WebCamAvatar') 
        // console.log(4)
    //   me.style.background = 'url('+data+') center center'
    //   me.style.backgroundSize = 'cover';
    } else {
      clearphoto();
    }
  }

  // Set up our event listener to run the startup process
  // once loading is complete.
  


        
  


// const API_EVENT_MIXIN = {
// 	_eventHandels: [],
// 	addEventListener(eventName, handel) {
// 		this._eventHandels.push({
// 			eventName: eventName,
// 			handel: handel,
// 		})
// 	},
// 	removeEventListener(eventName, handel) {
// 		this._eventHandels = this._eventHandels.filter(x => x.eventName !== eventName || x.handel !== handel);
// 	},
// 	dispatchEvent(eventName, event) {
// 		this._eventHandels.filter(x => x.eventName === eventName).forEach( e => e.handel(event) )
// 	},
// }


// API_EVENT_MIXIN.addEventListener('stopFrameEvent', ()=> {
//     console.log('stopFrameEvent')
//     takepicture()
// })



export default function WebCamAvatar ({ stopFrame = false }) {


    useEffect(()=>{
        // window.addEventListener('load', startup, false);
        startup()

        // let iid = setInterval(()=>{
            // takepicture()
        // },1000)
        return () => {
            // window.removeEventListener('load', startup);
            stop();
            // clearInterval(iid)
        }
    }, [])

    useEffect(()=>{
        if (streaming && stopFrame) {
            // window.dispatchEvent('stopFrameEvent')
            // API_EVENT_MIXIN.dispatchEvent('stopFrameEvent')
            takepicture()
            console.log('on stopFrame')
        }
        // return () => {
        //     window.removeEventListener('load', startup);
        //     stop()
        // }
    }, [stopFrame])

    return (
        // <div style={ {background:'gray', width:100, height: 100} }>
        <div id="WebCamAvatar" style={ {display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', background:'gray', width:300, height: 300, borderRadius:30, border:' 5px solid white', position: 'relative', overflow:"hidden", margin:'auto'} }>


       
        

        {/* <div class="WebCamAvatar-camera"> */}
            {/* <video style={{visibility: stopFrame ? 'hidden' : 'visible'}} id="WebCamAvatar-video">Video stream not available.</video> */}
            <video style={{position:"absolute", top:0, left:0, width:300, height:300, zIndex:1, objectFit:'cover'}} id="WebCamAvatar-video">Video stream not available.</video>
        {/* </div> */}
        {/* <canvas style={{visibility: !stopFrame ? 'hidden' : 'visible'}} id="WebCamAvatar-canvas" /> */}
            <canvas style={{ top:0, left:0, height: 300}} id="WebCamAvatar-canvas" />
        <div className="output" style={{display:'none'}}>
            {/* <button id="WebCamAvatar-startbutton">Take photo</button>  */}
            {/* <img id="WebCamAvatar-photo" alt="The screen capture will appear in this box."/>  */}
        </div>

        </div>
    )
}