

export function img_blob_to_url(blob) {
	let urlCreator = window.URL || window.webkitURL;
	return urlCreator.createObjectURL( new Blob([blob], {type: "image/jpeg"}) )
}

export function download(data, filename = 'style_transfer.jpg', type = "image/jpeg") {
	let urlCreator = window.URL || window.webkitURL;
  
	var file = new Blob([data], {type: type});
	if (window.navigator.msSaveOrOpenBlob) // IE10+
		window.navigator.msSaveOrOpenBlob(file, filename);
	else { // Others
		var a = document.createElement("a"),
			url = urlCreator.createObjectURL(file);
		a.href = url;
		a.download = filename;
		document.body.appendChild(a);
		a.click();
		setTimeout(function() {
			document.body.removeChild(a);
			urlCreator.revokeObjectURL(url);  
		}, 0); 
	}
  }


export function imgBase64_to_imgUrl(imgBase64) {
    const getMimeType = (blob) => {
        if ( blob.indexOf('PNG') > -1 ) return 'image/png'
        if ( blob.indexOf('JFIF') > -1 ) return 'image/jpeg'
        if ( blob.indexOf('GIF') > -1 ) return 'image/gif'
        if ( blob.indexOf('SVG') > -1 || blob.indexOf('svg') > -1 ) return 'image/svg+xml'
    }
    if (imgBase64 === null || !imgBase64) { 
        // return Promise.reject() 
        return '';
    }
    return 'data:' + getMimeType(atob(imgBase64)) + ';base64,' + imgBase64
}


  export default {
    img_blob_to_url,
	imgBase64_to_imgUrl,
    download
  }