// import logo from './logo.svg';
// import './App.css';

import React, {useState} from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useHistory,
  Redirect
} from "react-router-dom";


// import MeetingPage from './MeetingsPage';
// import MeetingList from './MeetingList'; 

// import StCards, {ScrImg} from './st/st_cards'; 
// import StyleTransferPage from './st/StyleTransferPage'; 

// import api from './api/api.js'


// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
// import './antd.dark.css'
// import { notification, Button } from 'antd';
import { WarningOutlined, PlusSquareOutlined, FireOutlined, LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled } from '@ant-design/icons';
import  {
      Button, Container,
      TextField,
      ActionButton,
      Spinner

} from '@sberdevices/plasma-ui';

import {
    IconEye,
    IconArrowRight
} from '@sberdevices/plasma-icons'

import axios from 'axios';

import QRmodal from './qrmodal'


import { Row as Row2, Col as Col2 } from 'antd';

import { IRow, ICol } from './irow'

import utils from './utils'



// function img_blob_to_url(blob) {
// 	let urlCreator = window.URL || window.webkitURL;
// 	return urlCreator.createObjectURL( new Blob([blob], {type: "image/jpeg"}) )
// }

// function download(data, filename = 'digital_peter_handwrite.jpg', type = "image/jpeg") {
// 	let urlCreator = window.URL || window.webkitURL;
  
// 	var file = new Blob([data], {type: type});
// 	if (window.navigator.msSaveOrOpenBlob) // IE10+
// 		window.navigator.msSaveOrOpenBlob(file, filename);
// 	else { // Others
// 		var a = document.createElement("a"),
// 			url = urlCreator.createObjectURL(file);
// 		a.href = url;
// 		a.download = filename;
// 		document.body.appendChild(a);
// 		a.click();
// 		setTimeout(function() {
// 			document.body.removeChild(a);
// 			urlCreator.revokeObjectURL(url);  
// 		}, 0); 
// 	}
// }

function go_hand(text = 'Проверка', setHandimg, setHandimg2, setLoading) {
    console.log('go_hand', text)
    // return

    setLoading(true)

    // axios.post('http://45.89.225.221:9101/peter_handwriting_with_qr', text, {responseType: "arraybuffer"} ).then(res => {
    // axios.post('http://45.89.225.221:9101/peter_handwriting_with_qr', text ).then(res => {
        // axios.post('http://45.89.225.221:9101/peter_handwriting_with_qr', 'Проверка' ).then(res => {
    // axios.post('http://45.89.225.221:9101/peter_handwriting_with_qr', {text:'Проверка'} ).then(res => {
    axios.get('http://45.89.225.221:9101/peter_handwriting_with_qr', { params: {text: text } } ).then(res => {
        console.log(res.data)

        setLoading(false)
        // document.getElementById('img-handwrite').src = img_blob_to_url( res.data )
        // document.getElementById('img-handwrite').src = 'http://45.89.225.221:9101/get_file?ref='+res.data.img

        setHandimg( 'http://45.89.225.221:9101/get_file?ref='+res.data.img )
        setHandimg2( 'http://45.89.225.221:9101/get_file?ref='+res.data.img_with_logo )
    }, err => {
        setLoading(false)
    })
}

function LogoHome() {
	let history = useHistory();
	return (
		// <img src="sbersight_demo_logo.svg" 
		// 		onClick={() => history.push('/') }
		// 		style={{verticalAlign: 'middle', width: '90px', margin: '15px'}}/> 

        // <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
        <img src="sberai_logo.svg" 
				onClick={() => history.push('/') }
				style={{verticalAlign: 'middle', width: '120px', margin: '0 0 15px'}}/> 
	)
}



export default function PeterHandPage() {
  let history = useHistory();
  let [val, setVal] = useState('')
//   let [handimg, setHandimg] = useState('http://45.89.225.221:9101/get_file?ref=PeterHandwritingDemo.jpg')
//   let [handimg2, setHandimg2] = useState('http://45.89.225.221:9101/get_file?ref=PeterHandwritingDemo.jpg')
  let [handimg, setHandimg] = useState('/peter_demo.jpg')
  let [handimg2, setHandimg2] = useState('/peter_demo.jpg')
  let [is_loading, setLoading] = useState(false)
  return (<>



    <Container>

        	{/* <div style={{textAlign:'center'}}>
				<LogoHome/> 
			</div>    */}

        <Button view="clear" size="l" pin="circle-circle" contentLeft={<DownloadOutlined /> } 
				// style={{fontSize:'24px', position:'absolute', right:'5px', top:'5px', display: 1 ? 'block' : 'none'}} 
				style={{fontSize:'24px', position:'absolute', right:'260px', top:'15px', display: 1 ? 'block' : 'none'}} 
				onClick={()=> fetch(handimg2).then( t => t.blob().then( b => utils.download(b, 'digital_peter_handwrite.jpg') )) } />

        {/* <QRmodal value={handimg2} style={{fontSize:'24px', position:'absolute', right:'60px', top:'5px', display: 1 ? 'block' : 'none'}} /> */}
        <QRmodal value={handimg2} style={{fontSize:'24px', position:'absolute', right:'315px', top:'15px', display: 1 ? 'block' : 'none'}} />

        <img className="sber_180" src="/sber_180_en.png"
				  style={{ position:'absolute', right:'47px', top:'18px', width:'200px'}} />
        {/* <img className="sber_180" src="/sber_180.png"
				  style={{ position:'absolute', right:'30px', bottom:'30px', width:'200px'}} /> */}

        <br />
        <br />
        <br />
        <br />

        <Row2 gutter={40}>
          <Col2 xs={24} md={10}>
            <LogoHome/> 
            <br />
            
            <h1 onClick={() => history.push('/')} style={{fontSize: '3rem', lineHeight:1, margin:'1rem 0'}}>Почерк Петра&nbsp;I</h1>
            <p>Искусственный интеллект покажет,
            <br />как Петр&nbsp;I написал бы то&nbsp;же&nbsp;самое.</p>


            <TextField 
              as="textarea"
              className="TextFieldArea"
              id="input-handerite"
              label="Напишите слово или фразу" 
              // rows={10}
              value={val} 
              // defaultValue={val} 
              onChange={e => setVal(e.target.value)} 
              // onKeyPress={e => { if (e.code == 'Enter') go_hand(val, setHandimg, setHandimg2, setLoading) }}
              style={{ margin: '10px auto'}}
              />

            {/* <ActionButton view="primary" onClick={() => go_hand(val, setHandimg, setHandimg2, setLoading)}> */}
                      {/* <IconArrowRight color="inherit" size="s" /> */}
                      {/* <IconEye color="inherit" size="s" /> */}
                      {/* <Spinner color="white" size={20}/> */}
            {/* </ActionButton> */}

            <Button view="primary" onClick={() => go_hand(val, setHandimg, setHandimg2, setLoading)}>

              { !is_loading ? 'Написать' : <Spinner color="white" size={20}/> }
               
              {/* <IconArrowRight color="inherit" size="s" /> */}

              {/* <div id="loader" style={{ display: is_loading ? 'block' : 'none'}}> */}
                {/* <LoadingOutlined /> */}
                {/* <Spinner color="white" size={20}/> */}
              {/* </div> */}
            </Button>

            <br />
            <br />

          </Col2>
          <Col2 xs={24} md={14}>

            <div className="paper">
              {/* <div className="img-handwrite4" style={{backgroundImage: 'url(' + handimg + ')'}}></div> */}
              <img className="img-handwrite5" src={handimg} />
            </div>
          </Col2>
        </Row2>

        {/* <div style={{textAlign:'center', marginTop:'5vh'}}>
            
                    <LogoHome/> 
                
            <h1 onClick={() => history.push('/')} style={{fontSize: '3rem'}}>Почерк Петра I</h1>
            <p>Искусственный интеллект покажет,
            <br /> как Петр I написал бы то же самое.</p>
        </div> */}
          
        <br />


          {/* <img id="img-handwrite" src="" style={{position: 'absolute', left: 0}} width="100%"/> */}
          {/* <img id="img-handwrite" src="http://45.89.225.221:9101/get_file?ref=PeterHandwritingDemo.jpg"  width="100%"/> */}
          
          <br />
          {/* <Button >Дом</Button> */}
          {/* <Button onClick={() => history.push('/style-transfer')}>StyleTransfer</Button>
          <br />
          <Button onClick={() => history.push('/digital-peter')}>Почерк Петра</Button>
          <br />
          <Button onClick={() => history.push('/face-swap')}>FaceSwap</Button> */}

          <style>
              {`
                    .img-handwrite2 {
                        filter: invert(1);
                        mix-blend-mode: color-dodge;
                    }
                    .img-handwrite3 {
                        filter: invert(1);
                        /* background-blend-mode: color-dodge; */
                        mix-blend-mode: color-dodge;
                        height:50vh;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                    .img-handwrite4 {
                        mix-blend-mode: multiply;
                        height:100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-size: calc(min(250px, 100%));
                    }
                    img.img-handwrite5 {
                      width: auto;
                      max-height: 100%;
                      mix-blend-mode: multiply;
                      max-width: 100%;
                    }
                    html, body { min-height: 100vh; } 
					          body { 
                        
                    }
                    .paper {
                        background: url('/paper.jpg') center no-repeat; 
                        background-size: cover;
                        height:80vh;
                        padding: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .TextFieldArea textarea { 
                      height: 40vh; 
                      resize: none;
                      white-space: normal;
                      background: #152b2d9b;
                      overflow: auto;
                    }
                    .TextFieldArea textarea:focus { 
                      background: #152b2deb;
                    }
                    
              `}
          </style>

          {/* {val} */}
     

        {/* <br /> */}

        
        
        {/* <img className="img-handwrite2" src="http://45.89.225.221:9101/get_file?ref=PeterHandwritingDemo.jpg" width="100%" /> */}
        

        {/* <Button onClick={() => go_hand()}>test</Button> */}


        <img id="img-handwrite" src="" style={{position: 'absolute', left: 0}} width="100%"/>


        {/* </div> */}
    </Container>

    {/* <div className="footer" style={{height:'175px'}}> */}
    {/* <div className="footer" style={{background:'none'}}>

        <small>powered by</small>
        <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 

    </div> */}

    </>
  )
}