import { Button } from "@sberdevices/plasma-ui"
import { IconHouse } from "@sberdevices/plasma-icons"
import { //WarningOutlined, PlusSquareOutlined, FireOutlined, 
	LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled, QrcodeOutlined } from '@ant-design/icons';
import axios from "axios";
import { useEffect, useState } from "react";

import utils from './utils'


export default function EthicsPage() {
    // const [elena_status, setElenaStatus] = useState(-1)
    // const [timestamp, setTimestamp] = useState(0)

    // let iid = null

    // useEffect(()=> {
    //     console.log('init')
    //     iid = setInterval(() => {
    //         get_status()
    //     }, 2000)
    //     return () => {
    //         clearInterval(iid)
    //     }
    // },[elena_status])

    // const update_scene = (num) => {

    //     if (elena_status == num ) {
    //         console.log('update_scene block', elena_status, num)
    //         return
    //     } else {
    //         console.log('update_scene go', elena_status, num)
    //     }

    //     setElenaStatus(num)

    //     // return

    //     let v_wait = document.getElementById('v-wait');
    //     let v_main = document.getElementById('v-main');
    //     let v_end = document.getElementById('v-end');

    //     if (!v_wait) return;
    //     if (!v_main) return;
    //     if (!v_end) return;

    //     v_wait.pause()
    //     v_main.pause()
    //     v_end.pause()
    //     switch(num) {

    //         case 1:
    //             v_wait.currentTime = 0
    //             v_main.currentTime = 0
    //             v_end.currentTime = 0
    //             v_main.play();
    //             v_main.muted = false;
    //             break;
    //         case 2:
    //             v_wait.currentTime = 0
    //             v_main.currentTime = 78.7
    //             v_end.currentTime = 0
    //             v_main.play();
    //             v_main.muted = false;
    //             break;
    //         case 3:
    //             v_wait.currentTime = 0
    //             v_main.currentTime = 0
    //             v_end.currentTime = 0
    //             break;
    //         case 4:
    //             v_wait.currentTime = 0
    //             v_main.currentTime = 0
    //             v_end.currentTime = 0
    //             v_end.play();
    //             v_end.muted = false;
    //             break;

    //         case 0:
    //         default:
    //             v_wait.currentTime = 0
    //             v_main.currentTime = 0
    //             v_end.currentTime = 0
    //             v_wait.play();
    //             break;
    //     }
    // }

    // const get_status = () => {
    //     return axios.get('http://45.89.225.221:9101/get_status_elena').then(res => {
    //         // if (elena_status == -1) {
    //         //     setTimeout(()=>{
    //         //         update_scene(0)
    //         //     }, 500)
    //         // }


    //         // console.log('res', res)
    //         let new_status = parseInt( res.data.status_elena );
    //         // if (status != new_status ) {

    //             // console.log(status, new_status)

    //             // setStatus(new_status)
    //             update_scene( new_status )
    //         // }
    //         setTimestamp(res.data.timestamp)
    //     }, err => {
    //         console.log('err', err)
    //         return false
    //     })
    // }
    // const on_timeupdate = (e) => {
    //     if ( elena_status == 1 && e.target.currentTime >= 79 ) {
    //         // update_scene(2)
    //         setElenaStatus(2)
    //     }
    //     // console.log(e.target.currentTime)
    // }
    const download = () => {

    }
    return (
        <div className={"EthicsPage "}>

{/* <Button view="clear" size="l" pin="circle-circle" contentLeft={<DownloadOutlined /> } 
				// style={{fontSize:'24px', position:'absolute', right:'5px', top:'5px', display: _.is_painted ? 'block' : 'none'}} 
				style={{fontSize:'24px', position:'absolute', right:'235px', top:'15px', display: _.is_painted ? 'block' : 'none'}} 
				 /> */}


{/* onClick={() => download()}  */}
            <Button className="btn-download" view={'primary'} size="l" 
            onClick={()=> fetch('http://45.89.225.221:9101/get_file?ref=elena/transferred_ethical_logo.jpg&ts=' + Math.random()).then( t => t.blob().then( b => utils.download(b, 'СберПортрет.jpg') )) }
            ><DownloadOutlined style={{fontSize:"25px"}} /> &nbsp; Скачать портрет</Button>

    
            <div className="wrapper">
                <div className="big_cover">
                    <div className="img_box" style={{
                        backgroundImage: 'url(http://45.89.225.221:9101/get_file?ref=elena/transferred_ethical_logo.jpg&ts=' + Math.random() + ')'
                    }} />
                    {/* <img className="qr_box" src="/qr_share_elena.gif" /> */}
                </div>

            </div>


            <style>{`
            .btn-download {
                position:absolute;
                top: 75vh;
                z-index:1
            }
                .panel {
                    position:absolute;
                    left:0;
                    top:0;
                    z-index:1
                }
                .elena_video {
                    width: 100%;
                    display: none;
                }
                .EthicsPage { 
                    line-height:0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100vh;
                }
                .mini_cover .img_box {
                    width:50%;
                    height: 42.3%;
                    position:absolute;
                    left:0;
                    bottom:0;
                    background-size:cover;
                }
                .mini_cover .qr_box {
                    width:50%;
                    height: 42.3%;
                    position:absolute;
                    right:0;
                    bottom:0;
                    background: #fff url('/qr_share_elena.gif') no-repeat center center;
                    background-size:contain;
                }
                .mini_cover {
                    display: none;
                }
                .big_cover .img_box {
                    width:100%;
                    height: 100%;
                    position:absolute;
                    left:0;
                    bottom:0;
                    background-size:cover;
                }
                .big_cover .qr_box {
                    width: min(30vw, 30vh);
                    position:fixed;
                    right:4%;
                    bottom:3%;
                }

                .wrapper {
                    width:min(100vw, 75vh);
                    height:min(133.3vw, 100vh);                  
                    position:relative;
                }

            `}</style>

        </div>
    )
}