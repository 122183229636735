import { 
    // Badge, Button, Card, CardBody, CardBody1, CardContent, CardHeadline2, CardHeadline3, CardMedia, 
    Header, 
    // TextField 
} from "@sberdevices/plasma-ui";
import { Container } from "@sberdevices/plasma-ui";
import { Row, Col } from "antd";
import axios from "axios";
import { useState, useEffect } from "react";
// import utils from "../utils";

import { message } from "antd";



import { useHistory, useParams } from "react-router";
import Debug from "../../elem/Debug";

import ModelInputForm from './input/ModelInputForm'; 
import ModelOutputForm from './output/ModelOutputForm'; 






function API_Exec(model = {}, input_data = { text: "галактика в огне" } ) {
    return axios.post('http://45.89.225.221:8111/fbmp/api/v1/proxy', {
        "model_endpoint_id": model.id,
        "data": input_data
    }).then(res => {
        return res.data
    })

    // {
    //     "model_endpoint_id": 1,
    //     "data": {
    //         "text": "галактика в огне"
    //     }
    // }
}

function API_LoadModelList() {
    return axios.get('http://45.89.225.221:8111/fbmp/api/v1/models').then(res => {
        return res.data.models
    })

    // return Promise.resolve(
    //     [
    //         {
    //             "created": "Tue, 23 Nov 2021 16:41:03 GMT",
    //             "credentials": {
    //                 "credentials_id": 1,
    //                 "credentials_login": "potanin.m.st@sberbank.ru",
    //                 "credentials_password": "Andyhaid181!",
    //                 "credentials_url": "https://api.aicloud.sbercloud.ru/public/v2/auth"
    //             },
    //             "default_params": {},
    //             "description": "Generate picture via text",
    //             "endpoint": "https://api.aicloud.sbercloud.ru/public/v2/inference/v1/predict/kfserving-1636384016/kfserving-1636384016/",
    //             "headers": {
    //                 "x-api-key": "26ca6b81-05a8-4bb7-bf1b-6a8a521a3419",
    //                 "x-workspace-id": "c93ddf49-514a-4323-a39f-843488615cc0"
    //             },
    //             "id": 1,
    //             "input_fields": [
    //                 {
    //                     "datasource": [],
    //                     "description": "Input text to generate image",
    //                     "field_type": "text",
    //                     "id": 1,
    //                     "is_required": true,
    //                     "placeholder": "TEXT",
    //                     "proxy_as": "text"
    //                 }
    //             ],
    //             "method": "POST",
    //             "model_name": "Peter handjob",
    //             "output_fields": [
    //                 {
    //                     "description": "Input text to generate image",
    //                     "field_type": "text",
    //                     "id": 1,
    //                     "placeholder": "TEXT",
    //                     "proxy_as": "text"
    //                 }
    //             ],
    //             "primary_image_url": "http://sberai.site:8110/imgcloud/qr/1636553508/screen.jpg",
    //             "proxy_pass_config": "default_proxy",
    //             "proxy_pass_config_id": 1,
    //             "secondary_image_url": "http://sberai.site:8110/imgcloud/qr/1636553508/screen.jpg",
    //             "tags": [],
    //             "updated": "Tue, 23 Nov 2021 16:41:03 GMT"
    //         }
    //     ]
    // )
}



// function ModelCard({model, onSelect=()=>{}}) {
//     return (
//         <Card onClick={() => onSelect(model)} style={{marginBottom:20}} 
//         tabIndex={1}
//         outlined={true}
//         scaleOnFocus={true}
//         scaleOnHover={true}>
//             <CardBody>
//                 <CardMedia
//                     src={model.primary_image_url}
//                     // placeholder={model.primary_image_url}
//                     // ratio={'1/1'}
//                     // ratio={'16/9'}
//                     ratio={'4/3'}
//                     />
//                 <CardContent cover={true}>
//                     {/* <img src={model.primary_image_url} width={100} /> */}
//                     {/* <img src={model.secondary_image_url} width={100} /> */}
//                     <CardHeadline2>{model.model_name}</CardHeadline2>
//                     <CardBody1 style={{marginBottom: 9, marginTop: 5}}>{model.description}</CardBody1>
//                     {/* <p>{JSON.stringify(model.tags)}</p> */}

//                     <div>
//                         {/* {model.tags.map((x,i) =>  */}
//                         {['NLP', 'CNN'].map((x,i) => 
//                             <Badge key={i} text={ x } view="primary" style={{display:'inline', marginRight:'5px', background: ''}} /> 
//                         )} 
//                     </div>
                    
//                 </CardContent>
//             </CardBody>
//         </Card>
//     )

// }


// const img_input_to_blob_base64 = (img_blob) => {
//     return new Promise((resolve, reject) => {
//         let reader = new FileReader();
//         reader.readAsBinaryString(img_blob);
//         reader.onload = function() {
//             let img_base64 = btoa(reader.result);

//             resolve(img_base64)
//         };
//     })
// }












export default function ModelsPage() {
    const [models, set_models] = useState([])
    const [active_model, set_active_model] = useState({})
    const [exec_results, set_exec_results] = useState({})
    const [exec_results_prepare, set_exec_results_prepare] = useState([])
    const [exec_inputs, set_exec_inputs] = useState({})
    const [exec_inputs_full, set_exec_inputs_full] = useState({})
    const [result_img, set_result_img] = useState('')

    const { id } = useParams()

    const history = useHistory();
    
    const [exec_loading, set_exec_loading] = useState(false)

    const loadModels = () => {
        API_LoadModelList().then( res => {
            set_models(res);
            set_active_model(res?.find(x => x.id == id));
        }  )
    }

    const prepare_results = (input_data, results ) => {
        return active_model.output_fields.map((x,i) => (
            {...x, result: results[x.proxy_as], input: { ...input_data } }
        ))
    }
    
    const onExec = (input_data = {}, inputs_full_data = {}) => {
        if ( exec_loading == true) return;
        set_exec_inputs(input_data)
        set_exec_inputs_full(inputs_full_data)
        set_exec_loading( true )
        API_Exec(active_model, input_data).then( res => {
            // set_result_img( imgBase64_to_imgUrl( res.image ) )
            set_exec_results( res )
            set_exec_results_prepare( prepare_results( inputs_full_data, res )  )
            set_exec_loading( false )
        }, err => {
            set_exec_results( {} )
            set_exec_loading( false )
            message.error({
                // content: 'Ошибка на сервере: ' + err.request.status + ' ' + err.message,
                content: 'Ошибка на сервере: ' + err.message,
                // className: 'custom-class',
                // style: {
                //     marginTop: '40vh',
                //     borderRadius: '100px',
                //     padding: '40px'
                // },
            });
        } )
    }

    useEffect(()=> {
        loadModels()
    }, [])

    return (
        // <Container style={{background:'url(cfb_bg.jpg) center top', backgroundSize: 'cover'}}>
        <Container>
            <style>
                {`
                    body { background: url(cfb_bg.jpg) center top; background-size: cover;  }
                `}
            </style>
            <div>

                {/* <h1>ModelsPage</h1> */}
                <br/>
                <br/>
                <div style={{display:'flex'}}>
                    <img src={'/sberai_logo.svg'} width="150" style={{marginRight:40}} />
                    <img src={'/airi_logo.png'} width="150" />
                </div>
                <br/>
                <br/>
                <br/>
                {/* <h1>Fusion Brain</h1> */}
                {/* <p>Мультимодальная мультитаск архитектура Fusion Brain</p> */}
                <Header back onClick={()=>history.push('/fb')} title={active_model?.model_name} subtitle={active_model?.description} logo={active_model?.primary_image_url} />
                <br/>


 

                        {/* {active_model?.primary_image_url && <img src={active_model.primary_image_url} width={100} /> } */}
                        {/* <img src={active_model.secondary_image_url} width={100} /> */}
                        {/* <CardHeadline3>{active_model.model_name}</CardHeadline3> */}
                        {/* <CardBody>{active_model.description}</CardBody> */}

                        <Row gutter={[20]}>
                            <Col span={12}>

                                <ModelInputForm model={active_model} onExec={ onExec } execLoading={exec_loading} />
                                
                                {/* <ModelOutputForm model={active_model} results={ exec_results_prepare } /> */}
                                {/* { result_img && <img src={result_img} width={300} /> } */}
                            </Col>
                            <Col span={12}>
                                {/* <pre style={{fontSize:10, lineHeight:1}}>{JSON.stringify(active_model, true, "      ")}</pre> */}
                                <ModelOutputForm model={active_model} results={ exec_results } results_prepare={ exec_results_prepare } />

                            </Col>
                        </Row>


                {/* <Debug json={active_model} /> */}
       
            </div>
        </Container>
    )
}