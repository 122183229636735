import { Button } from "@sberdevices/plasma-ui"
import { IconHouse } from "@sberdevices/plasma-icons"
import { //WarningOutlined, PlusSquareOutlined, FireOutlined, 
	LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled, QrcodeOutlined } from '@ant-design/icons';
import axios from "axios";
import { useEffect, useState } from "react";
import { message } from "antd";


export default function EthicsPage() {
    // const [status, setStatus] = useState(0)
    // const [timestamp, setTimestamp] = useState(0)
    // const [model_ts, setTimestamp] = useState(0)

    const [elena_status, set_elena_status] = useState(-1)
    const [model_ts, set_model_ts] = useState(0)
    const [status_ts, set_status_ts] = useState(0)

    const [loading, set_loading] = useState(false)


    const open_dialog = (id_input) => {
		document.querySelector(id_input).click()
	}

    useEffect(()=> {
        console.log('init')
        let iid = setInterval(() => {
            get_status()
        }, 1000)
        return () => {
            clearInterval(iid)
        }
    },[elena_status])

    const on_load_src_img = (input) => {
        console.log(input)
        if (input.files && input.files[0]) {
            on_magic(input.files[0])
        }
    }
    const set_status = (number = 0) => {
        return axios.get('http://45.89.225.221:9101/set_status_elena', {
            params: {
                status_elena: number
            }
        }).then(res => {
            console.log('res', res)
        }, err => {
            console.log('err', err)
            return false
        })
    }
    const get_status = () => {
        return axios.get('http://45.89.225.221:9101/get_status_elena').then(res => {
            // console.log('res', res)
            set_elena_status(res.data.status_elena)
            set_status_ts(new Date(res.data.status_timestamp).getTime())
            set_model_ts(new Date(res.data.model_timestamp).getTime())
        }, err => {
            console.log('err', err)
            return false
        })
        // http://45.89.225.221:9101/get_status_elena
        // http://45.89.225.221:9101/rollback_elena
        // http://45.89.225.221:9101/get_file?ref=elena/transferred_no_logo.jpg
        // http://45.89.225.221:9101/get_file?ref=elena/transferred_logo.jpg
        // http://45.89.225.221:9101/set_status_elena?status_elena=3
    }
    const on_magic = (src_img_blob) => {
        set_loading(true)
        console.log('onMagic')
        let api_url = 'http://45.89.225.221:9101/transfer_elena';
        let qData = new FormData();
        qData.append("original_img", src_img_blob);
        return axios.post(api_url, qData).then(res => {
            console.log('res', res)
            set_model_ts(model_ts + 1)
            set_loading(false)
        }, err => {
            console.log('err', err, err.request, err.message)

            // if (err.request && err.request.status == 401 ) { // не авторизован
            //     // window.open('/login')
            //     //document.location.href = ('/login')
            //     this.redirect('/login')
            //   }
            //   notification.open({
            //     duration: 0,
            //     message: err.message,
            //     description: err.config?.url,


            message.error({
                // content: 'Ошибка на сервере: ' + err.request.status + ' ' + err.message,
                content: 'Ошибка на сервере: ' + err.message,
                // className: 'custom-class',
                // style: {
                //     marginTop: '40vh',
                //     borderRadius: '100px',
                //     padding: '40px'
                // },
            });
            set_loading(false)
            return false
        })
    }
    const update_scene = (num) => {
        set_elena_status(num);
        if ( num == 1 || num == 2) {
            
        }
    }
    return (
        <div style={{textAlign:'center'}}>


            <br />
            <br />


			{/* <Button view="clear" size="l" pin="circle-circle" contentLeft={<CameraOutlined /> } 
				// style={{fontSize:'24px', position:'absolute', left:'15px', top:'15px', display: 'block'}} 
            onClick={() => open_dialog('#srcimg')} /> */}

            <input id="srcimg" style={{display:'none'}} type="file" accept=".jpg,.jpeg,.png" 
					onChange={(e) => on_load_src_img(e.target)}/>


            <h1 style={{lineHeight:1.1}}>Этический искусственный интеллект</h1>


            {/* <Button view="secondary" size="l" onClick={() => get_status()} >get_status</Button> */}
            {/* <Button view="secondary" size="l" onClick={() => set_status(10)} ><IconHouse/></Button> */}

            {/* <Button view={status == 0 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(0)} >0</Button>
            <Button view={status == 1 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(1)} >1</Button>
            <Button view={status == 2 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(2)} >2</Button>
            <Button view={status == 3 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(3)} >3</Button>
            <Button view={status == 4 ? 'primary' : "secondary"} size="s" onClick={() => update_scene(4)} >4</Button> */}

            <Button view={"secondary"} style={{margin:'5px'}} size="s" onClick={() => set_status(0)} >Ждем</Button>
            <Button view={"secondary"} style={{margin:'5px'}} size="s" onClick={() => set_status(1)} >Рассказ</Button>
            <Button view={"secondary"} style={{margin:'5px'}} size="s" onClick={() => set_status(2)} >Портрет готов</Button>
            <Button view={"secondary"} style={{margin:'5px'}} size="s" onClick={() => set_status(3)} >Финальный стоп-кадр</Button>
            <Button view={"secondary"} style={{margin:'5px'}} size="s" onClick={() => set_status(4)} >Пока</Button>


            <br />
            <br />

            <Button view={"primary"} size="l" onClick={() => open_dialog('#srcimg')} >{loading ? '...' : 'Загрузить фото'}</Button>

            {/* {status} |  */}
            {/* {timestamp} */}

            <br />
            <br />

            {/* <img src={'http://45.89.225.221:9101/get_file?ref=elena/transferred_logo.jpg&ts=' + timestamp} width="200" /> */}
            {/* <img onClick={() => open_dialog('#srcimg')} src={'http://45.89.225.221:9101/get_file?ref=elena/transferred_no_logo.jpg&ts=' + model_ts} style={{width:'auto', maxHeight:'50vh'}} /> */}
            <img onClick={() => open_dialog('#srcimg')} src={'http://45.89.225.221:9101/get_file?ref=elena/transferred_ethical_no_logo.jpg&ts=' + model_ts} style={{width:'auto', maxHeight:'50vh'}} />

      

        </div>
    )
}