import { TextField } from "@sberdevices/plasma-ui";
import Debug from "../../../elem/Debug";

import {imgBase64_to_imgUrl} from '../../utils'


export default function ModelOutputField({field={}, res, res_prepare}) {
    return (
        <div>
            {/* {field?.field_type} */}
            <p>{field?.description} <span style={{opacity:0.3}}> — {field?.proxy_as}</span></p>
            {/* <p>{JSON.stringify(field)}</p> */}
            {/* <pre style={{fontSize:10, lineHeight:1}}>{JSON.stringify(field, true, "      ")}</pre> */}
            {/* <pre style={{fontSize:10, lineHeight:1}}>{JSON.stringify(res, true, "      ")}</pre> */}
            {/* <pre style={{fontSize:10, lineHeight:1}}>{JSON.stringify(res_prepare, true, "      ")}</pre> */}
            { field?.field_type == 'text' && 
                <TextField value={res} label={field.description} />
            }
            { field?.field_type == 'image' && 
                <img src={imgBase64_to_imgUrl( res )} style={{maxWidth:"100%", filter: field?.channel_inv ? 'sepia(0.5)' : 'none'}} />
            }
            { field?.field_type == 'polygons' && 
                // res.length
                // <svg width="600px" height="460px" viewBox="0 0 600 460">
                <>
                    {/* <Debug json={res_prepare} /> */}
                    <GenSvgImgWithPoligon res={res} res_prepare={res_prepare} width="100%" />
                </>
            }
            {/* <br/> */}
        </div>
    )
}

function GenSvgImgWithPoligon({res, res_prepare, ...rest}) {
    if (!res) return '';

    let ema_h = res.reduce((acum, val)=> acum + val?.polygon[3], 0) / res.length;
    return (
        <svg viewBox={[0,0,res_prepare?.input?.image?.w, res_prepare?.input?.image?.h].join(' ')} {...rest} >
            {/* <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> */}
            <image x="0" y="0" href={imgBase64_to_imgUrl( res_prepare?.input?.image?.value )}></image>
            {res?.map((x,i)=>(
                <g key={i} fill="none">
                    <rect stroke="#000000" x={x.polygon[0]} y={x.polygon[1]} width={x.polygon[2]} height={x.polygon[3]}></rect>
                    <text fontFamily="HelveticaNeue, Arial" fontSize={ema_h/2} fontWeight="normal" fill="#000000">
                        <tspan x={x.polygon[0]} y={x.polygon[1]-5}>{x.text}</tspan>
                    </text>
                </g>
            ))}
        </svg>
    )
}

// function ModelInputForm_defValues() {
//     return model?.input_fields?.map((x,i))
// }

