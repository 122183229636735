
import HelpSlide from './slides/HelpSlide'
import HelpLikeSlide from './slides/HelpLikeSlide'
import LikeSlide from './slides/LikeSlide'
import ResultSlide from './slides/ResultSlide'
import SelectSlide from './slides/SelectSlide'
import StartSlide from './slides/StartSlide'

import config from './config.json'
import { useEffect, useState } from 'react'
import Debug from '../../elem/Debug'
import { Route, Switch, useHistory } from 'react-router'
import { Button } from '@sberdevices/plasma-ui'

import TinderCards from './TinderCards'
import Footer from '../../elem/Footer'
import QuestionsPartLikes from './QuestionsPartLikes'


// function QuestionPage({question={}}) {
//     // const part_name = qs_part.pa
//     return (
//         <div></div>
//     )
// }



// function LikeTestPage() {
//     return (
//         <div style={{padding:0}}>

//             <div className='PanelLikeCards'>
//                 <TinderCards/>
//                 {/* {showAdvanced ? <Advanced /> : <Simple />} */}
//                 {/* <div className='row'> */}
//                     {/* <p style={{ color: '#fff' }}>Show advanced example</p>  */}
//                     {/* <Switch checked={showAdvanced} onChange={setShowAdvanced} /> */}
//                 {/* </div> */}
//             </div>
//         </div>
//     )
// }



function QuestionsPart({ part={}, onEnd=()=>{} }) {
    const questions = part?.questions
    const [question_index, set_question_index] = useState(0)
    const [answers, set_answers] = useState([])
    const [show_help, set_show_help] = useState(true)

    const next_q = (variant) => {
        // console.log('next_q', variant, answers.length); 
        console.log('next_q', [...answers, {...variant}] ); 
        set_answers( [ ...answers, {...variant}] )
        if (question_index + 1 >= questions.length ) {
            onEnd( answers )
            set_question_index(0)
            return
        }
        set_question_index(question_index + 1 )
    }

    const count_variants = questions?.[question_index]?.variants.length 

    useEffect(() => {
        set_answers([])
    },[part])

    if (count_variants == 1 && show_help)  {
        return (
            <div>
                {/* help 2 */}
                <HelpLikeSlide onNext={() => set_show_help(false)} />


                {/* <Button onClick={() => set_show_help(false)}>next</Button> */}
    
                {/* <Debug json={answers} /> */}
            </div>
        )        
    }

    return (
        <div style={{textAlign:'center'}}>
            { count_variants == 1 ?
                <LikeSlide question={questions[question_index]} onNext={next_q} />
                // <div className='PanelLikeCards'>
                //     <TinderCards 
                //         questions={questions || []} 
                //         question={questions[question_index]} 
                //         onNext={(v) => next_q(v)} 
                //     />
                // </div>
                :
                <SelectSlide question={questions[question_index]} key={question_index} onNext={next_q} />
            }
            {/* <Button onClick={()=>onEnd([1,2,3])}>End</Button> */}
            {/* --- */}
            {/* {question_index} */}
            {/* <Debug json={answers} /> */}
            {/* <Debug json={questions[question_index]} /> */}
        </div>
    )
}

function answersRating(answers, part_config) {
    let rating = answers.reduce((acum, val, index)=>(
        {   
            ...acum, 
            [val?.type]: (acum?.[val?.type] || 0) + val?.value
        }), part_config.reduce((a, v) => ({ ...a, [v]: 0}), {}) )

    rating[topRating(rating)] += 1;

    return rating
}
    
// function topRating(answers, current_part) {
function topRating(rating) {
    // let rating = answersRating(answers, current_part.part_config)
    let top = Object.keys(rating).sort((a,b) => rating[b] - rating[a] )[0]

    // console.log( rating, top )
    return top;
}
function hashReport(full_rating) {
    return btoa( JSON.stringify( full_rating ) )
}

function TestPage2({video}) {

    const [current_part, set_current_part] = useState( config['start'] )
    // const [current_part, set_current_part] = useState( config['human_human'] )
    const [full_report, set_full_report] = useState([])
    const [full_rating, set_full_rating] = useState([])


    const history = useHistory()

    const onEndPart = report => {
        const rating = answersRating(report, current_part.part_config)
        const topResKey = topRating(rating)

        console.log('onEndPart', report)

        let new_full_report = [ ...full_report, report ];
        let new_full_rating = [ ...full_rating, rating ];
    
        if ( config[topResKey] ) {
            set_current_part( config[topResKey] );
            set_full_report( new_full_report )
            set_full_rating( new_full_rating )
            
        } else {
            // history.push('/who/result/' + topResKey + '--' + hashReport(new_full_rating) )
            history.push('/who/result/' + hashReport(new_full_rating) )
        }
    }



    return (
        <div>
            { current_part.part_type == 'likechoice' && 
                <QuestionsPartLikes video={video} part={current_part} onEnd={onEndPart} />
            }
            { current_part.part_type == 'singlechoice' && 
                <QuestionsPart part={current_part} onEnd={onEndPart} />
            }
            {/* <Debug json={full_report} /> */}
        </div>
    )
}


// function TestPage() {
//     // const [slides, set_slides] = useState([])
//     const [questions, set_questions] = useState([])
//     const [question_index, set_question_index] = useState(0)
//     const [answers, set_answers] = useState([])
//     const [rank, set_rank] = useState([])
//     const [current_part_name, set_current_part_name] = useState('start')
//     const [current_part, set_current_part] = useState({})

//     const read_questions_parts = (part_name = 'start') => {
//         set_rank( config[part_name].part_config )
//         set_questions( config[part_name].questions )
//         // return config[part_name].questions
//     }

//     const add_answers = (variant) => {
//         set_answers( [ ...answers, variant] )
//     }
//     const on_end_part = () => {
//         read_questions_parts('nature');
//         set_question_index(0)
//     }
//     const next_q = () => {
//         if (question_index + 1 >= questions.length ) {
//             on_end_part()
//             return
//         }
//         set_question_index(question_index + 1 )
//     }
//     const prev_q = () => {
//         if (question_index <= 0 ) return
//         set_question_index(question_index - 1 )
//     }

//     useEffect(()=> {
//         read_questions_parts('start')
//         // read_questions_parts('human_human')
//         // read_questions_parts('nature')
//         // read_questions_parts('sign')
//     }, [])

//     const history = useHistory();



//     return (
//         <div style={{padding:50}}>


//             <Button view="primary" onClick={()=>{history.push('/who')}}>Начало</Button>
//             <Button view="primary" onClick={()=>{history.push('/who/result')}}>Результат</Button>

//             {/* <StartSlide onNext={()=>{console.log('next'); read_questions_parts('start'); }} /> */}
//             {/* <HelpSlide onNext={()=>{console.log('next')}} /> */}

//             {/* <SelectSlide question={questions[question_index]} onNext={()=>{ console.log('next'); set_question_index(question_index < questions.length ? question_index + 1 : question_index ) }} /> */}
//             <SelectSlide question={questions[question_index]} onNext={(v)=>{ 
//                 console.log('next', v); 
//                 add_answers(v); 
//                 next_q() 
//             }} />
//             <LikeSlide question={questions[question_index]} onNext={()=>{ console.log('next'); prev_q() }} />

//             {/* <ResultSlide /> */}

//             {/* <Debug json={answers.reduce((acum, val, index)=>acum[val.type] = acum[val.type] ? acum[val.type] + val.type : val.type, {})} /> */}
//             {/* <Debug json={answers.reduce((acum, val, index)=>[...acum, val?.type], [])} /> */}
//             <Debug json={answers.reduce((acum, val, index)=>({...acum, [val?.type]: 1 + (acum?.[val?.type] || 0)}), [])} />
//             <Debug json={answers} />
//             {JSON.stringify(rank)}


//             <Debug json={rank} />
//             <Debug json={questions[question_index]} />

//             <Debug json={questions} />
//         </div>
//     )
// }


function video_blob_to_url(blob) {
	let urlCreator = window.URL || window.webkitURL;
	return urlCreator.createObjectURL( new Blob([blob], {type: "video/mp4"}) )
}


export default function Page() {
    const history = useHistory();

    const [video_1, set_video_1] = useState('')
    const [video_2, set_video_2] = useState('')
    const [video_3, set_video_3] = useState('')

    useEffect(()=> {

        fetch('/who/video/0001.m4v')
            .then( t => t.blob().then( b => {
                set_video_1( video_blob_to_url(b) ) 
                // console.log(1, video_1)
            })) 
        fetch('/who/video/0002.m4v')
            .then( t => t.blob().then( b => {
                set_video_2( video_blob_to_url(b) ) 
                // console.log(1, video_1)
            })) 
        fetch('/who/video/0003.m4v')
            .then( t => t.blob().then( b => {
                set_video_3( video_blob_to_url(b) ) 
                // console.log(1, video_1)
            })) 

    }, [])

    if (!video_1 || !video_2 || !video_3) return 'Загрузка...'


    return (
        // <div style={{overflow:'hidden', maxHeight:'100vh'}}>
            <div style={{padding:50, userSelect:'none' }}>
                <div onDoubleClick={()=>history.push('/who')} style={{position: 'absolute', zIndex:5, background:'red',left:0, top:0, width: 100, padding:'50px 20px', textAlign: 'center', opacity:0 }}>reset</div>
                {/* <div onClick={()=>history.push('/who')} style={{position: 'absolute', zIndex:5, background:'red',left:0, top:0, width: 100, height:50, textAlign: 'center' }}>reset</div> */}
                <Switch>
                    <Route path="/who/result/:res">
                        <ResultSlide onNext={()=>{history.push('/who')}} />
                    </Route>
                    <Route path="/who/result">
                        <ResultSlide onNext={()=>{history.push('/who')}} />
                    </Route>
                    <Route path="/who/test">
                        <TestPage2 video={video_3} />
                        {/* <TestPage /> */}
                    </Route>
                    <Route path="/who/help2">
                        <HelpLikeSlide onNext={()=>{history.push('/who/test')}} />
                    </Route>
                    {/* <Route path="/who/like">
                        <LikeTestPage />
                    </Route> */}
                    <Route path="/who/help">
                        <HelpSlide video={video_2} onNext={()=>{history.push('/who/test')}} />
                    </Route>
                    <Route path="/who">
                        <StartSlide video={video_1} onNext={()=>{history.push('/who/help')}} />
                    </Route>
                </Switch>
                <Footer />
            </div>
        // </div>
    )
}