// import logo from './logo.svg';
// import './App.css';

import React from 'react';

import {
  // BrowserRouter as Router,
  HashRouter as Router,
  Switch,
  Route,
  // Link,
  // useParams,
  // useHistory,
  // Redirect
} from "react-router-dom";


// import MeetingPage from './MeetingsPage';
// import MeetingList from './MeetingList'; 

// import StCards, {ScrImg} from './st/st_cards'; 
import StyleTransferPage from './st/StyleTransferPage'; 
import PeterHandPage2 from './st/PeterHandPage2'; 
import PeterFacePage from './st/PeterFacePage'; 
import EpochPage from './st/EpochPage'; 
import EpochPageDemo from './st/EpochPageDemo'; 
import FoodPage from './st/FoodPage'; 

import EthicsPage from './st/EthicsPage'; 
import EthicsPageDemo from './st/EthicsPageDemo'; 
import EthicsPageHDemo from './st/EthicsPageHDemo'; 
import EthicsPageHDemo2 from './st/EthicsPageHDemo2'; 
import EthicsPageShare from './st/EthicsPageShare'; 
// import TestPage from './st/StyleTrasferPage'; 
// import PeterFaceLightPage from './st/PeterFacePage'; 
import StartPage from './st/StartNightPage'; 


import ModelListPage from './st/FusionBrain/ModelListPage'; 
import ModelPage from './st/FusionBrain/ModelPage'; 


import WhoAreYou from './st/WhoAreYou'
import WhoAreYouT from './st/WhoAreYou/TinderCards'

// import api from './api/api.js'


// import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import './antd.dark.css'

// import { notification, Button } from 'antd';
// import { WarningOutlined, PlusSquareOutlined, FireOutlined, LoadingOutlined, DownloadOutlined, CameraOutlined, CameraFilled } from '@ant-design/icons';
// import  {
//       Button, Container,

// } from '@sberdevices/ui';

import axios from 'axios';





class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
        list: [],
    }
    
  }

  render() {
    return (
      <Router>



          {/* <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
            </ul>
          </nav> */}

          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>

            {/* <Route path="/test">
                <TestPage />
            </Route>
            <Route path="/test2">
                <PeterFaceLightPage />
            </Route> */}

            <Route path="/fb/:id">
                <ModelPage />
            </Route>
            <Route path="/fb">
                <ModelListPage />
            </Route>

            <Route path="/epoch/demo">
                <EpochPageDemo />
            </Route>

            <Route path="/epoch">
                <EpochPage />
            </Route>

            <Route path="/food">
                <FoodPage />
            </Route>

            <Route path="/who/t">
              <div className='PanelLikeCards'>
                  {/* <TinderCards/> */}
                  <WhoAreYouT />
                  {/* {showAdvanced ? <Advanced /> : <Simple />} */}
                  <div className='row'>
                      <p style={{ color: '#fff' }}>Show advanced example</p> 
                      {/* <Switch checked={showAdvanced} onChange={setShowAdvanced} /> */}
                  </div>
              </div>
                
            </Route>
            <Route path="/who">
                <WhoAreYou />
            </Route>

            <Route path="/ethics/demo">
                <EthicsPageDemo />
            </Route>
            {/* <Route path="/ethics/hdemo">
                <EthicsPageHDemo />
            </Route> */}
            <Route path="/ethics/hdemo">
                <EthicsPageHDemo2 />
            </Route>
            <Route path="/ethics/share">
                <EthicsPageShare />
            </Route>
            <Route path="/ethics">
                <EthicsPage />
            </Route>
            
            <Route path="/style-transfer">
                <StyleTransferPage />
            </Route>
            <Route path="/digital-peter-handwrite">
                <PeterHandPage2 />
            </Route>
            <Route path="/digital-peter-faceswap">
                <PeterFacePage />
            </Route>
            <Route path="/">
                <StartPage />
            </Route>

            {/* <Route path="/">
                <Redirect to="/meetings" />
            </Route> */}

          </Switch>



      </Router>
    )
  }
}

export default App;
