
import React from 'react';

import {
    // BrowserRouter as Router,
    // Switch,
    // Route,
    // // Link,
    // useParams,
    // Redirect,
    useHistory
  } from "react-router-dom";

import StModel from './st_model' 

import axios from 'axios'

import { 
	// LoadingOutlined, DownloadOutlined, CameraOutlined, 
    // QrcodeOutlined 
    CameraFilled, 
} from '@ant-design/icons';

import  {
	Button,
} from '@sberdevices/plasma-ui';


// import { utils.img_blob_to_url, download } from './utils'
import utils from './utils'
import { useState } from "react";

import { message } from 'antd';



function LogoHome() {
	let history = useHistory();
	return (
		<img src="sbersight_demo_logo.svg" 
				onClick={() => history.push('/') }
				style={{verticalAlign: 'middle', width: '90px', margin: '15px'}}/> 
	)
}


export default class PageWrapper extends React.Component {
    constructor(props) {
      super(props)
  
      this.state = {
          style_list: [],
      }
    }

    componentDidMount() {
        axios.get('epoch_style_list.json').then(res => {
            console.log(res.data)
            this.setState({ style_list: res.data })
        })
            
    }

    render() {
        return (<>
            {/* {this.state.style_list.toString()} */}
            <Page category_style_list={this.state.style_list} />
        </>)
    }
}


function Page({category_style_list}) {

    // console.log('category_style_list',  category_style_list)

    // const [ category_style_list, set_category_style_list ] = useState([]);

    // let category_style_list = [
    //     { title: 'Пользовательские', items: [], hide:true, custom_user_loaded: true },

    //     { title: 'До революции', items: [] },
    //     { title: '1917-1950', items: [] },
    //     { title: '1951-1979', items: [] },
    //     { title: '1980-1990', items: [] },
    //     { title: '1991-2000', items: [] },
    //     { title: '2001-2010', items: [] },
    //     { title: '2010+', items: [] },

    //     // , 1917-1950, 1951-1979, 1980-1990,1991-2000,2001-2010,2010+
    // ]

    // for ( let i = 1; i <= 32; i++) {
    //     let cat_index = Math.floor( Math.random() * (category_style_list.length - 1) ) + 1
    // //   category_style_list.push({
    //     category_style_list[ cat_index ].items.push({
    //         id: 'style'+i,
    //         filename: 'styles/mini/' + 'style'+i+'.jpg',
    //         userstyle: false,
    //         // category: get_random_category_id(categories)
    //         category_id: cat_index,
    //     })
    // }

    
    return (
        <div>
            <div style={{textAlign:'center'}}>
				<LogoHome/> 
			</div>   

            <StModel
                startText={mi => <>
                    <img src="/cover_gagarin.jpg" height="200" onClick={() => mi.on_load_src_img_demo('/cover_gagarin.jpg')} />
					
					<h1>Эпохи в лицах</h1>
					<p>Искусственный интеллект впишет
            			<br /> ваш портрет в одно из лиц эпохи.</p>
						<br />
					<Button onClick={() => mi.open_dialog('#srcimg')}>
						<CameraFilled /> &nbsp; Загрузить фото
					</Button>
                </>}
                defStyles={category_style_list}
                userStyles={false}
                compareMode={true}
                filenameForDownload="style_transfer.jpg"
                onMagic={(style_info, src_img_blob) => {
                    console.log('onMagic')
                    
                    // let api_url = 'http://45.89.225.221:9101/transfer_with_parameters';
                    // let api_url = 'http://45.89.225.221:9101/peter_faceswap';
                    let api_url = 'http://45.89.225.221:9101/peter_faceswap_with_qr';
                    let qData = new FormData();
                    qData.append("original_img", src_img_blob);

                    let qParams = {
                        model_type: 'fast',
                        model: 'epoch',
                        is_peter: false,
                        user_style: style_info.userstyle,
                    }
                    if (style_info.userstyle) {
                        qData.append("style_img", style_info.blob);
                    } else {
                        qParams['style_id'] = style_info.id
                        qParams['epoch_id'] = style_info.category_id
                    }
            
                    return axios.post(api_url, qData, {
                        // responseType: "arraybuffer",
                        params: qParams,
                    }).then(res => {
                        console.log('res', res)
                        if ( res.data.status != "ok") {
                            message.error({
                                content: res.data.reason,
                                className: 'custom-class',
                                style: {
                                    marginTop: '40vh',
                                    borderRadius: '100px',
                                    padding: '40px'
                                },
                            });
                            return Promise.reject()
                        }
                        return {
                            painted_img_url:            'http://45.89.225.221:9101/get_file?ref=' + res.data.img,
                            painted_img_url_with_logo:  'http://45.89.225.221:9101/get_file?ref=' + res.data.img_with_logo,
                        }
                    }, err => {
                        console.log('err', err)
                        return false
                    })

                }}
            ></StModel>

            <style>
                { `
                html, body { min-height: 100vh; } 

                div.badgeDone {
                    display: inline;
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    line-height: 57px;
                    text-align: center;
                    /* margin: 53px; */
                    margin: 62px 5px;
                    padding: 0;
                }
                
                .footer {
                    text-align: center;
                    position: fixed;
                    z-index: 10;
                    bottom: 0;
                    left: 0;
                    right: 0;
                  }
                  
                ` }
            </style>


            <div className="footer">
                <small>powered by</small>
                <img src="sberai_logo.svg" style={{verticalAlign: 'middle', width: '90px', margin: '10px'}}/> 
            </div>

        </div>
    )
}